import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { BiCalendar } from "react-icons/bi";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import "./DateTimeRangeSelector.css";

function DateTimeRangeSelector(props) {
  const CalendarStartDateSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="calendar_date" onClick={onClick} ref={ref}>
      {startDate ? value : props.startplaceholderText}
      <BiCalendar className="calendar_icon" />
    </div>
  ));

  const CalendarEndDateSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="calendar_date" onClick={onClick} ref={ref}>
      {startDate ? value : props.endplaceholderText}
      <BiCalendar className="calendar_icon" />
    </div>
  ));

  function setFromDate(date) {
    setStartDate(date);
    props.setFromDate(date);
  }
  function setToDate(date) {
    setEndDate(date);
    props.setToDate(date);
  }
  let [startDate, setStartDate] = useState(null);
  let [endDate, setEndDate] = useState(null);
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() > selectedDate.getTime();
  };
  const filterEndPassedTime = (time) => {
    if (startDate) {
      const endDate = new Date(startDate);
      const selectedDate = new Date(time);

      return endDate.getTime() < selectedDate.getTime();
    } else {
      const currentDate = new Date();
      const selectedDate = new Date(time);

      return currentDate.getTime() > selectedDate.getTime();
    }
  };

  return (
    <>
      <div className="dateSelector">
        <DatePicker
          selected={startDate}
          onChange={(date) => setFromDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={moment.utc().add(props.forward, 'day')._d}
          //Manual time Day limit
          minDate={moment.utc().subtract(props.backward, 'day')._d}
          showTimeSelect
          filterTime={props.filterTime ? filterPassedTime : null}
          dateFormat="MMMM d, yyyy h:mm aa"
          className="no-border"
          timeIntervals={10}
          customInput={<CalendarStartDateSelector />}
        />
      </div>
      <div className="dateSelector">
        <DatePicker
          selected={endDate}
          onChange={(date) => setToDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate==null ? moment.utc().subtract(props.backward, 'day')._d:startDate}
          maxDate={moment.utc().add(props.forward, 'day')._d}
          showTimeSelect
          filterTime={filterEndPassedTime}
          dateFormat="MMMM d, yyyy h:mm aa"
          className="no-border"
          timeIntervals={10}
          customInput={<CalendarEndDateSelector />}
        />
      </div>
    </>
  );
}

export default DateTimeRangeSelector;
