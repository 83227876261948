import "./UserManualHours.css";
import DataTableBase from "../../ui/DataTableBase";
import { ReactSession } from 'react-client-session';
import { useState,useMemo  } from "react";
import { fetchImageFromAPI, get, post } from "../../api/apiCalls";
import { useParams } from "react-router-dom";
import moment from 'moment';
import SearchSection from "../../ui/SearchSection";
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import Alert from 'react-bootstrap/Alert';
import UserShorProfile from "../../elements/summaryElements/UserShorProfile";
import { baseURL } from "../../api/env";
import DefaultProfileImage from "../../../images/default.jpg"
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";

function UserManualHours(props) {
  let rowID, requestrowID, statusrowID;
  const params = useParams();
  let [data, setData] = useState([]);
  let requestStatus = ["Requested","Approved","Rejected"];
  let user = ReactSession.get("User");
  let [euser,setUser] = useState(0);
  let [connect,setConnect] = useState(0);
  let [loading, setLoading] = useState(true);
  const columns = [
    {
      name: "Date",
      selector: (row) => row.Date,
      sortable: true,
      reorder: true,
    },
    {
      name: "Project",
      selector: (row) => row.Project,
      sortable: true,
      reorder: true,
    },
    {
      name: "Start Time",
      selector: (row) => row.startTime,
      sortable: true,
      reorder: true,
    },
    {
      name: "End Time",
      selector: (row) => row.endTime,
      sortable: true,
      reorder: true,
    },
    {
      name: "Hours",
      selector: (row) => row.Hours,
      sortable: true,
      reorder: true,
    },
    {
      name: "reason",
      selector: (row) => row.reason,
      sortable: true,
      reorder: true,
    },
    {
      name: "Approver",
      selector: (row) => row.Manager,
      sortable: true,
      reorder: true,
    },
    {
      name: "Approval Date",
      selector: (row) => row.approvalDate,
      sortable: true,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      button: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "Approved",
          classNames: ["approved"],
        },
        {
          when: (row) => row.status == "Rejected",
          classNames: ["rejected"],
        },
        {
          when: (row) => row.status == "Requested",
          classNames: ["requested"],
        },
      ],
      cell: (row) => (
        (requestrowID = "request-buttons-" + row.id.toString()),
        (statusrowID = "request-status-" + row.id.toString()),
        (rowID = row.status),
        (
          <div>
            <div
              className="manual-hours-buttons"
              id={requestrowID}
              style={{ display: rowID == "Requested" ? "block" : "none" }}
            >
              <button
                className="approve-button"
                onClick={() => ApproveRequest(row, requestrowID, statusrowID)}
                id={row.id}
              >
                Approve
              </button>
              <button
                className="reject-button"
                onClick={() => RejectRequest(row, requestrowID, statusrowID)}
                id={row.id}
              >
                Reject
              </button>
            </div>
            <div
              id={statusrowID}
              value={row.status}
              style={{ display: rowID == "Requested" ? "none" : "block" }}
            >
              {row.status}
            </div>
          </div>
        )
      ),
      sortable: true,
      reorder: true,
    },
  ];


  const ApproveRequest = (row, requestrowID, statusrowID) => {
    console.log(row, requestrowID, statusrowID);
    changeStatus(row,1);
    getRequests();

  };

  const RejectRequest = (row, requestrowID, statusrowID) => {
    console.log(row, requestrowID, statusrowID);
    changeStatus(row,2);
    getRequests();
  };

  let changeStatus=
  async(requestData,status) => {
    let formatedValue = requestData.Hours.toString()
    let keys = {
      date : moment(requestData.Date).format("YYYY-MM-DD"),
      status : status,
      managerID : user.id,
      userID : requestData.userID,
      project: requestData.Project,
      hours: formatedValue
    };
   let result = await post("Request/ManualTime/ChangeStatusByDate",keys);
    console.log("UPDATING MANUAL TIME: ", result)
    alert(result)
   getRequests();
  };

  let rsummary= null;
  const getRequests = 
    async () => {
      let data = [];
      rsummary = await get(`Request/ManualTime/UserManualTime?id=${params.employeeId}`);
      console.log(rsummary);
      for(let i = 0 ; i < rsummary.length ; i++ ) {
          data.push( {
            id : i,
            Date : moment(rsummary[i].Date).format("YYYY-MM-DD"),
            Hours : rsummary[i].hours,
            Manager : rsummary[i].managerName,
            Project: rsummary[i].projectName,
            reason : rsummary[i].reason,
            status : requestStatus[rsummary[i].status],
            userID : rsummary[i].userID,
            approvalDate: rsummary[i].approvalDate == null? '' : moment(rsummary[i].approvalDate).format('YYYY-MM-DD'),
            startTime: rsummary[i].startTime,
            endTime:rsummary[i].endTime
          });
      }
      
      setData(data);
      
  }
  useMemo(() => {
    getRequests();
    // componentWillMount events
  },[]);
  const getUser = async () => {
   
    
    let keys = {
      id : params.employeeId
    };
    let u = await post("Users/GetUserById",keys);
    console.log("U : ", u)
    u.image = u.image === null ? DefaultProfileImage : await fetchImageFromAPI(`${baseURL}TrackingData/GetProfileImages?ImagePath=${u.image}`)
   
      
 
    setUser(u);
    setLoading(false)
  }
   if(connect==0){
    setConnect(1);
    getUser();
  }
  
  return (
    <div className="summary">
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <SearchSection />
          <div className="summaryMain">
            <div className="user-profile-subheader">
              <UserShorProfile
                employeeName={euser.firstName + ' ' + euser.lastname}
                employeePronoun={euser.pronoun}
                employeeDesignation={euser.jobDescription}
                employeeID={euser.id}
                employeeStatus="Online"
                employeeLocation={euser.city}
                employeePicture={euser.image}
              />
              <ManagerSubHeaderLinks id={params.employeeId}/>
            </div>
            <div className="summaryDiary">
              <div>
                <Alert variant="info">
                  <Alert.Heading>Manual Time Instructions</Alert.Heading>
                  <p>
                    The manual time feature allows you to add time to your tracker time in case any problem happens with the tool, if you forgot to turn on the tracker or if you have an OS that is not supported. 
                    <br/>
                    Please take instructions from you manager in case of questions. 
                  </p>
                  <hr />
                  <p className="mb-0">
                  Note: MT needs to be approved by your manager.
                  </p>
                </Alert>
              </div>
              <div className="summaryContent">
                <div className="manual-hours">
                  {(() => {
                          if (data.length>0){
                              return (
                                <DataTableBase columns={columns} data={data} fileName={"Manual_Time_Requests"} />
                              )
                          }
                          return null;
                  })()}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UserManualHours;
