import Header from "../components/layout/header/Header";
import DashboardMain from "../components/layout/main/DashboardMain";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
function ManagerDashboard() {
  return (
    <div>
      <Header pageName="Manager Dashboard" />
      <ManagerSidebar />
      <DashboardMain />
    </div>
  );
}

export default ManagerDashboard;
