import "./Profile.css";
import ProfileImage from "../../../images/Sughra.jpeg";
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { post, put } from "../../api/apiCalls";
import { FiHelpCircle } from "react-icons/fi";
import { FaRegUserCircle } from "react-icons/fa";
import { MdOutlineDashboardCustomize, MdLogout, MdPeopleOutline  } from "react-icons/md";
import useProfileImage from "../../../hooks/useProfileImage";
function Profile() {
  const image = useProfileImage();
  async function logout() {
    let jobId = sessionStorage.getItem("jobID")
    if(jobId !== null){
      let keys = {
        jobId,
        username: user.username
  
      }
      let sessionKeys = {
        ID: parent(sessionStorage.getItem("session")),
        Online: false
      }
      await post("WebTracker/RemoveJob", keys)
      await put("DesktopApp/Online", sessionKeys)
      sessionStorage.clear();
      window.location.href = '/';
    }
    sessionStorage.clear();
    window.location.href = '/';
  }


  let user = ReactSession.get("User");
  
 
  return (
   
    <div className="profile-full">
    <div className="profile-box">
      {image !== null &&(
        <div className="profile-box-small">
          <div>
            <img
              className="profile-image"
              src={image}
              alt="profileImage"
            />
          </div>
          <div className="profile-details">
            <div className="profile-name">{user.firstName+' '+user.lastname}</div>
            <div className="designation">{user.jobDescription}</div>
          </div>
        </div>
      )}
      <div className="profile-navigation">
        <div>
          {user.role == 3 || user.role == 1 && (
            <Link to="/manager"><MdPeopleOutline /> Manager Dashboard</Link>
          )}
        </div>
        <div>
          {user.role !=4  && (
            <Link to="/summary"><MdOutlineDashboardCustomize  /> My Dashboard</Link>
          )}
        </div>
        <div>
          {user.role !=4  && (
            <Link to="/profile"><FaRegUserCircle /> Profile</Link>
          )}
        </div>
        <div>
          {user.role == 4  && (
            <Link to="/team"><MdPeopleOutline /> Team Dashboard</Link>
          )}
        </div>
        <div>
          <a href='https://sirius-support.atlassian.net/servicedesk/customer/portals' target="_blank"><FiHelpCircle /> Help</a>
        </div>
        <div>
          <Link onClick={logout} ><MdLogout /> Logout</Link>
        </div>
      </div>
    </div>
    <div className="notifications"></div>
  </div>
  );
}

export default Profile;
