import Header from "../components/layout/header/Header";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import UserDailyDiary from "../components/manager/dashboard/UserDailyDiary";

function UserDiary() {
  return (
    <div>
      <Header pageName="Dashboard" />
      <ManagerSidebar />
      <UserDailyDiary />
    </div>
  );
}

export default UserDiary;
