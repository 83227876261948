import { NavLink } from "react-router-dom";

import "./SubHeaderLinks.css";

function SubHeaderLinks() {
  return (
    <div className="links">
      <ul>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/summary">
            Summary
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/diary" id='step-5'>
            Diary
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default SubHeaderLinks;
