import "./UserOvertime.css";
import DataTableBase from "../../ui/DataTableBase";
import { ReactSession } from 'react-client-session';
import { useState,useMemo  } from "react";
import { fetchImageFromAPI, post, get } from "../../api/apiCalls";
import { useParams } from "react-router-dom";
import moment from 'moment';
import SearchSection from "../../ui/SearchSection";
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import Alert from 'react-bootstrap/Alert';
import { baseURL } from "../../api/env";
import DefaultProfileImage from "../../../images/default.jpg"
import UserShorProfile from "../../elements/summaryElements/UserShorProfile";
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";

function UserOvertime(props) {
  let rowID, requestrowID, statusrowID;
  console.log("rowID: ", rowID)
  console.log("requestrowID: ", requestrowID)
  console.log("statusrowID: ", statusrowID)
  const params = useParams();
  let [data, setData] = useState([]);
  let requestStatus = ["Requested","Approved","Rejected"];
  let user = ReactSession.get("User");
  let [euser,setUser] = useState(0);
  let [connect,setConnect] = useState(0);
  let [loading, setLoading] = useState(true)
  const columns = [
    {
      name: "Date",
      selector: (row) => row.Date,
      sortable: true,
      reorder: true,
    },
    {
      name: "Hours",
      selector: (row) => row.Hours,
      sortable: true,
      reorder: true,
    },
    {
      name: "Approver",
      selector: (row) => row.Manager,
      sortable: true,
      reorder: true,
    },
    {
      name: "reason",
      selector: (row) => row.reason,
      sortable: true,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      button: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "Approved",
          classNames: ["approved"],
        },
        {
          when: (row) => row.status == "Rejected",
          classNames: ["rejected"],
        },
        {
          when: (row) => row.status == "Requested",
          classNames: ["requested"],
        },
      ],
      cell: (row) => (
        (requestrowID = "request-buttons-" + row.id.toString()),
        (statusrowID = "request-status-" + row.id.toString()),
        (rowID = row.status),
        (
          <div>
            <div
              className="manual-hours-buttons"
              id={requestrowID}
              style={{ display: rowID == "Requested" ? "block" : "none" }}
            >
              <button
                className="approve-button"
                onClick={() => ApproveRequest(row, requestrowID, statusrowID)}
                id={row.id}
              >
                Approve
              </button>
              <button
                className="reject-button"
                onClick={() => RejectRequest(row, requestrowID, statusrowID)}
                id={row.id}
              >
                Reject
              </button>
            </div>
            <div
              id={statusrowID}
              value={row.status}
              style={{ display: rowID == "Requested" ? "none" : "block" }}
            >
              {row.status}
            </div>
          </div>
        )
      ),
      sortable: true,
      reorder: true,
    },
  ];


  const ApproveRequest = (row, requestrowID, statusrowID) => {
    console.log(row, requestrowID, statusrowID);
    changeStatus(row,1);
    getRequests();

  };

  const RejectRequest = (row, requestrowID, statusrowID) => {
    console.log(row, requestrowID, statusrowID);
    changeStatus(row,2);
    getRequests();
  };

  let changeStatus= async(requestData,status) => {
    let keys = {
      date : moment(requestData.Date).format("YYYY-MM-DD"),
      status : status,
      managerID : user.id,
      userID : requestData.userID
    };
   await post("Request/Overtime/ChangeStatusByDate",keys);
   getRequests();
  };
  let rsummary= null;

  const getRequests = async () => {
      let data = [];
      rsummary = await get(`Request/Overtime/UserOvertime?id=${params.employeeId}`);
      for(let i = 0 ; i < rsummary.length ; i++ ) {
          data.push( {
            id : i,
            Date : moment(rsummary[i].Date).format("YYYY-MM-DD"),
            Hours : rsummary[i].hours,
            Manager : rsummary[i].managerName,
            reason : rsummary[i].reason,
            status : requestStatus[rsummary[i].status],
            userID : rsummary[i].userID
          });
      }
      
      console.log("DATA: ", data);
      
      setData(data);
      
  }
  useMemo(() => {
    getRequests();
    // componentWillMount events
  },[]);
  const getUser = async () => {
   
    
    let keys = {
      id : params.employeeId
    };
    let u = await post("Users/GetUserById",keys);
    u.image = u.image === null ? DefaultProfileImage : await fetchImageFromAPI(`${baseURL}TrackingData/GetProfileImages?ImagePath=${u.image}`)
    console.log(u);
   
      
 
    setUser(u);
    setLoading(false)
  }
  if(connect==0){
    setConnect(1);
    getUser();
  }
  return (
    <div className="summary">
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
        <SearchSection />
        <div className="summaryMain">
          <div className="user-profile-subheader">
            <UserShorProfile
              employeeName={euser.firstName + ' ' + euser.lastname}
              employeePronoun={euser.pronoun}
              employeeDesignation={euser.jobDescription}
              employeeID={euser.id}
              employeeUsername={euser.username}
              employeeLocation={euser.city}
              employeePicture={euser.image}
            />
            <ManagerSubHeaderLinks id={params.employeeId}/> 
          </div>
          <div className="summaryDiary">
            
          <Alert variant="success" className="ot-info">
              <Alert.Heading>Over Time Instructions</Alert.Heading>
              <p>
              The over time feature will allow you to extend the project weekly hour cap that your manager defined.
                  It will not add time to your total time, rather extend the maximum hours counted toward your payroll time.
                  Example: Cap time is 40 hrs, with OT of 4hr your maximum payroll time will be of 44hr. 
                  <br/>
                  Please be mindful that those additional 4hrs still need to be tracked either by the tool or by manual time.
              </p>
              <hr />
              <p className="mb-0">
                  Note: OT needs to be approved by your manager.
              </p>
          </Alert>
            
            <div className="summaryContent">
              <div className="manual-hours ot-hours">
              
              {(() => {
                      if (data.length>0){
                          return (
                            <DataTableBase columns={columns} data={data} fileName={"Overtime_Requests"} />
                          )
                      }
                      return null;
              })()}
              </div>
            </div>
          </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UserOvertime;
