import "./Modal.css";

function ConfirmDeleteModal(props) {
  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal-text">
        Are you sure you want to delete these cards?
      </div>
      <button
        className="confirmation-modal-button confirm"
        onClick={() => props.ConfirmDelete()}
      >
        Confirm
      </button>
      <button
        className="confirmation-modal-button cancel"
        onClick={props.onCancel}
      >
        Cancel
      </button>
    </div>
  );
}

export default ConfirmDeleteModal;
