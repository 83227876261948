import "./Modal.css";

function ConfirmActivateModal(props) {
  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal-text">
        Are you sure you want to set these cards as active?
      </div>
      <button
        className="confirmation-modal-button confirm"
        onClick={() => props.ConfirmActivate()}
      >
        Confirm
      </button>
      <button
        className="confirmation-modal-button cancel"
        onClick={props.onCancel}
      >
        Cancel
      </button>
    </div>
  );
}

export default ConfirmActivateModal;
