import { Link } from "react-router-dom";
import EmployeeCard from "../../elements/summaryElements/EmployeeCard";
import { fetchImageFromAPI, get } from "../../api/apiCalls";
import React, { useState,useMemo } from "react";
import { ImSearch } from "react-icons/im";
import { IconContext } from "react-icons";
import { ReactSession } from 'react-client-session';
import { baseURL } from "../../api/env";
import DefaultProfileImage from "../../../images/default.jpg"
import { BounceLoader } from 'react-spinners'
import "./EmployeeSidebar.css";
import "../../ui/SearchButton.css";

export function ManagerEmployeeSidebar () {
  let [users,setUsers] = useState([]);
  let [items,setItems] = useState(null); 
  let [loading, setLoading] = useState(false);
  let [hasNext, setHasNext] = useState(false);
  let [pageNumber, setPageNumber] = useState(1);
  let [totalUsers, setTotalUsers] = useState(0);
  let [loadedUsers, setLoadedUsers] = useState(0);
  let [employee, setEmployee] = useState();
  let [disableSearch, setDisableSearch] = useState(true);
  let [disableSearchInfos, setDisableSearchInfos] = useState(false)
  const user = ReactSession.get("User")
  
  const getSummaryDetails = async (searchParam) => {
    setLoading(true)
    setDisableSearchInfos(false)
    users = await get(`Users/GetTeamMembersByManagerID?managerID=${user.id}&pageIndex=1&pageSize=25`).then();
    setHasNext(users.hasNextPage)
    setTotalUsers(users.totalResults)
    setLoadedUsers(users.totalLoaded)
    let u = users.users;
    await fetchImages(users.users)
    setUsers(u);
    let datas =[];
    for (const user of users.users) {
      if(searchParam!=null)
        if(!user.firstName.toLowerCase().includes(searchParam.toLowerCase()))
          continue;
      if(user.image == null)
        user.image = "default";
      let link = "/manager/summary/"+user.id;
      datas.push( <Link to={link}>
        <EmployeeCard
        employeeName={user.firstName+' '+user.lastname}
        employeeDesignation={user.job_Description}
        employeeID={user.id}
        employeePicture={user.image}
        location={user.city}
      />
      </Link>);
    }
    setItems(datas);
    setLoading(false)
  };
 
  const loadNextPage = async() => {
    setLoading(true)
    let nextPage = pageNumber + 1
    console.log("NEXT PAGE: ", nextPage)
    setPageNumber(nextPage)
    let nextUsers = await get(`Users/GetTeamMembersByManagerID?managerID=${user.id}&pageIndex=${nextPage}&pageSize=25`).then();
    console.log("NEXT USERS: ", nextUsers)
    setHasNext(nextUsers.hasNextPage)
    setLoadedUsers(nextUsers.totalLoaded)
    let u = nextUsers.users;
    await fetchImages(nextUsers.users)
    setUsers(prevUsers => [...prevUsers, ...u]);
    let datas =[];
    for (const user of nextUsers.users) {
      if(user.image == null)
        user.image = "default";
      let link = "/manager/summary/"+user.id;
      datas.push( <Link to={link}>
        <EmployeeCard
        employeeName={user.firstName+' '+user.lastname}
        employeeDesignation={user.job_Description}
        employeeID={user.id}
        employeePicture={user.image}
        location={user.city}
      />
      </Link>);
    }
  
    setItems(prevItems => [...prevItems, ...datas]);
    setLoading(false)
  }

  const search = async () => {
    setLoading(true)
    setDisableSearchInfos(true)
    let users = await get(`Users/SearchUsers?searchString=${employee}`)
    await fetchImages(users)
    let datas =[];
    for (const user of users) {
      let link = "/manager/summary/"+user.id;
      datas.push( <Link to={link}>
        <EmployeeCard
        employeeName={user.firstName+' '+user.lastname}
        employeeDesignation={user.job_Description}
        employeeID={user.id}
        employeePicture={user.image}
        location={user.city}
      />
      </Link>);
    }
    setItems(datas);
    setLoading(false)
  }

  const setEmployeeName = (value) => {
    setEmployee(value)
    if(value.length <= 0){
      console.log("NAME: ", employee)
      getSummaryDetails();
      setDisableSearch(true)
    }else if(value.length >= 3){
      setDisableSearch(false)
    }
  } 

  const fetchImages = async(items) => {
    await Promise.all(items.map(async (data) => {
      if(data.image === null){
        data.image = DefaultProfileImage
      }else{
        let image = await fetchImageFromAPI(`${baseURL}TrackingData/GetProfileImages?ImagePath=${data.image}`);
        data.image = image;
      }
    }));
  }

  useMemo(() => {
    
    getSummaryDetails(null);
  },[]);
  
    return (
      <>
        <div className="employee-sidebar">
          {/* <SearchButton searchButtonText="Search Employee" onchange={searchParam}/> */}
          <div className="sidebar-search-button">
            <div className="search-button-text search-input">
                <input className="search-input-text" placeholder="Search Employee" onChange={e => setEmployeeName(e.target.value)} />
                <IconContext.Provider
                value={{
                  color: "black",
                  size: "30px",
                }}
                >
                <button 
                  className="sideBarSearchBtn" 
                  onClick={search}
                  disabled={disableSearch}>
                  <ImSearch />
                </button>
              </IconContext.Provider>
            </div>
            {!disableSearchInfos &&(
              <p className="users-counter">USERS: {loadedUsers}/{totalUsers}</p>
            )}
          </div>
          {loading && (
            <div className="loading-div">
              <BounceLoader color="#004225"/>
            </div>

          )}
          <div className="employee-cards-list">
            {items}
          </div>
        </div>
        {!disableSearchInfos && hasNext &&(
          <div className="sidebar-search-users-footer">
            <button onClick={loadNextPage}>Load More Agents</button>
          </div>
        )}
      </>
    );
  
}
