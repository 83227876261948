import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { BiCalendar } from "react-icons/bi";

import "react-datepicker/dist/react-datepicker.css";

import "./DateSelector.css";

function DateSelector(props) {
  function setSelectorDate(date) {
    setDate(date);
    props.updateTimers(date);
    
  }
  const [newDate, setDate] = useState(props.date);
  const CalendarDateSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="calendar_date" onClick={onClick} ref={ref}>
      {newDate ? value : props.placeholderText}
      <BiCalendar className="calendar_icon" />
    </div>
  ));

  return (
    <div className="dateSelector">
      <DatePicker
        selected={newDate}
        onChange={(date) => setSelectorDate(date)}
        maxDate={new Date()}
        className="no-border"
        customInput={<CalendarDateSelector />}
      />
    </div>
  );
}


export default DateSelector;
