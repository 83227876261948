import React, { useState, useEffect} from 'react';
import { get, put } from "../api/apiCalls.js"
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import "./AdminAnnouncementsPage.css"
import AdminHeaderLinks from '../layout/header/AdminHeaderLinks.js';
import { ReactSession } from "react-client-session"

function AdminAnnouncementsPage() {
    const [announcement, setAnnouncement] = useState({})
    const [announcementText, setAnnouncementText] = useState('')
    const user = ReactSession.get("User")
    let getAnnouncement = async () => {
        let response = await get("Announcements");
        setAnnouncement(response[0])
        setAnnouncementText(response[0].announcement)
    }
    
    let updateAnnouncement = async() => {
        
        let keys = {
            ID: announcement.id,
            Announcement: announcementText,
            Updated_by: user.username
        }
        let response = await put("Announcements", keys)
        if(response){
            sessionStorage.setItem("announcement", keys.Announcement)
            alert(response)
        }
    }

    let cancel = () => {
        setAnnouncementText(announcement.announcement)
    }
    useEffect(() => {
        getAnnouncement()
    }, [])
    return(
        <div className="settingsContainer">
            <AdminHeaderLinks/>
            {  
                <Box sx={{ flexGrow: 1 }} className="settingsBody">
                    <Grid container spacing={2}>
                        <Grid xs={10}>
                            <h3 className="settingsLabel">Announcements</h3>
                        </Grid>
                        <Grid xs={10}>
                            {announcement !=null && (
                                <div className='announcement-form'>
                                    <label>Announcement</label>
                                    <textarea 
                                        required
                                        type="text" 
                                        rows={5}
                                        cols={100}
                                        value={announcementText} 
                                        onChange={(e) => setAnnouncementText(e.target.value)}
                                        
                                    />
                                    <div className='announcements-btns'>
                                        <button disabled={announcementText == ''} className='save-announcements-btn' onClick={updateAnnouncement}>Save</button>
                                        <button onClick={cancel} className='clear-announcements-btn'>Cancel</button>
                                    </div>
                                </div>
                            )}
                        </Grid>
                        
                    </Grid>
                </Box>
            }
        </div>
    )
}

export default AdminAnnouncementsPage;
