import Header from "../components/layout/header/Header.js";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar.js";
import DashboardManagerProjects from "../components/manager/teamDashboards/DashboardManagerProjects.js";

function ManagerProjectsDashboard() {
  return (
    <>
      <Header pageName="Manager Dashboard" />
      <ManagerSidebar />
      <DashboardManagerProjects />
    </>
  );
}

export default ManagerProjectsDashboard;
