import { HiCursorClick } from "react-icons/hi";
import keyboardLogo from "../../../../images/keyboard-logo.png";
import "./Productivity.css";

function Productivity(props) {
  const getBackgroundColor = () => {
    let color,
      productivity = props.productivityType;
    if (productivity.toLowerCase() == "productive") {
      color = "#3CBE52";
    } else if (productivity.toLowerCase() == "overtime"){
      color = "#fcd257"
    }else if (productivity.toLowerCase() == "unproductive" || productivity.toLowerCase() == "ot discard") {
      color = "#C34D57";
    } else if (productivity.toLowerCase() == "marked") {
      color = "#F1C40F";
    } else if (productivity.toLowerCase() == "manual time") {
      color = "#5669E1";
    }else
    {
      color = "#B0BEC5";
    }

    return color;
  };

  return (
    <div className="productivityCard">
      <div
        className="productivityType"
        style={{ backgroundColor: getBackgroundColor() }}>
        {props.productivityType}
      </div>
      
      <div className="settings">
        <div className="mouse">
          <span>{props.mouse}</span>
          <HiCursorClick />
        </div>
        <div className="keys">
          <span>{props.keys}</span>
          <img src={keyboardLogo} alt="keystrokes" />
        </div>
        <span className="project">{props.project}</span>
      </div>
    </div>
  );
}

export default Productivity;
