import './AuditDetails.css';
import {get, post, put} from "../../api/apiCalls";
import React, { useState, useMemo} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import DataTableBase from '../../ui/DataTableBase.js';
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";
import moment from 'moment/moment';
import { ReactSession } from 'react-client-session'
import AdditionalPaymentModal from '../../elements/dashboardElements/AdditionalPaymentModal'

function AdditionalPaymentsPage() {
  const [additionalPayments, setAdditionalPayments] = useState([])
  const [openAddModal, setOpenAddModal] = useState(false);
  let [loading,setLoading] = useState(false);
  let rowID, requestrowID, statusrowID;
  const loggedUser = ReactSession.get("User")
  const getAdditionalPayments = async() => {
    let response = await get("Payments/GetAllOtherPayments")
    let payments = [];
    for(let i = 0 ; i < response.length ; i++){
      let paymentEntity = response[i];
      let payment = {
        id: paymentEntity['id'],
        userID : paymentEntity['userID'],
        userName: paymentEntity['userName'],
        userEmail: paymentEntity['userEmail'],
        reason: paymentEntity['reason'],
        amount: paymentEntity['amount'],
        status: paymentEntity['status'],
        created_at: moment(paymentEntity['created_at']).format("YYYY-MM-DD HH:mm:ss"),
      }
      payments.push(payment);
    }
    setAdditionalPayments(payments);
    setLoading(false)
  }

  const columns = [
    {
      id: 'userName',
      name: 'Name',
      selector: (row) => row.userName,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'reason',
      name: 'Reason',
      selector: (row) => row.reason,
      minWidth: "300px",
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'amount',
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'status',
      name: "Status",
      selector: (row) => row.status,
      button: true,
      wrap: true,
      sortable: true,
      reorder: true,
      minWidth: "300px",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "Approved",
          classNames: ["approved"],
        },
        {
          when: (row) => row.status == "Rejected",
          classNames: ["rejected"],
        },
        {
          when: (row) => row.status == "Cancelled",
          classNames: ["cancelled"],
        },
        {
          when: (row) => row.status == "Pending Approval",
          classNames: ["requested"],
        },
      ],
      cell: (row) => (
        (requestrowID = "request-buttons-" + row.id),
        (statusrowID = "request-status-" + row.id),
        (rowID = row.status),
        (
          <div>
            <div
              className="manual-hours-buttons"
              id={requestrowID}
              style={{ display: rowID == "Pending Approval" ? "block" : "none" }}
            >
              <span>
                {rowID} | <button
                className="cancel-button"
                onClick={() => cancelRequest(row, requestrowID, statusrowID)}
                id={row.id}
              >
                cancel
              </button>
              </span>
              
            </div>
            <div
              id={statusrowID}
              value={row.status}
              style={{ display: rowID == "Pending Approval" ? "none" : "block" }}
            >
              {row.status}
            </div>
          </div>
        )
      ),
    },
    {
      id: 'created_at',
      name: 'Created Date',
      selector: (row) => row.created_at,
      sortable: true,
      reorder: true,
      wrap: true,
    },
  ]

  const cancelRequest = (row, requestrowID, statusrowID) => {
    CancelPaymentRequest(row);
    getAdditionalPayments();
  }

  let CancelPaymentRequest=
  async(requestData) => {
    let keys = {
      id : requestData.id,
      status: 'Cancelled',
      approved_by: loggedUser.firstName + ' ' + loggedUser.lastname
    };
   await put("Payments/UpdateRequestStatus",keys).then((response) => {
    alert(response)
   }, (error) => console.error(error))
   .catch((e) => console.error(e));

   getAdditionalPayments();
  };

  function openAddProjectsModal() {
    setOpenAddModal(true);
  }

  function closeAddModal() {
    setOpenAddModal(false);
  }

  const addPayment = async(projectObject) => {
    console.log(projectObject)
    closeAddModal()
    setLoading(true)
    let keys = {
      managerEmail: loggedUser.username,
      submited_by: loggedUser.firstName + " " + loggedUser.lastname,
      userID : projectObject.userID,
      userName : projectObject.userName,
      userEmail : projectObject.userEmail,
      reason : projectObject.reason,
      amount : projectObject.amount
    }
    console.log(keys)
    await post("Payments/AdditionalPayment", keys).then(() => alert("Adding new request"));
    getAdditionalPayments();
  }

  useMemo(() => {
    getAdditionalPayments()
    },[]);
  

  return (
    
    <div className='dashboardProjectsMain'>
       
      {  
            <Box sx={{ flexGrow: 1 }} className="settingsBody">
                <Grid container spacing={2}>
                    <Grid xs={10}>
                        <h3 className="settingsLabel">Additional Payments</h3>
                    </Grid>
                    <Grid xs={10}>
                      <button
                        className='project-add-button'
                        id='add-projects-button'
                        onClick={openAddProjectsModal}
                      >
                        Add Payment
                      </button>
                    </Grid>
                    <Grid xs={10} className="update-fields payments-fields">
                        { !loading && (
                          <DataTableBase
                          columns={columns}
                          data={additionalPayments}
                          tableName='Additional Payments'
                          fileName={"AdditionalPayments"}
                          />
                        )}
                        {loading && (
                          <LoadingSpinner/>
                        )}
                    </Grid>
                    
                </Grid>
            </Box>
        }
      {openAddModal && <AdditionalPaymentModal onCancel={closeAddModal} addPayment={addPayment} />}
    </div>
  );
}

export default AdditionalPaymentsPage;
