import { useState } from "react";
import "./SelectDropdown.css";

function SelectDropdown(props) {
  const [defaultSelectText, setDefaultSelectText] = useState(
    props.DefaultSelectText
  );
  const [showOptionList, setShowOptionList] = useState(false);
  const optionsList = props.OptionsLists;
 

 

  // This method handles the click that happens outside the
  // select text and list area
  const handleClickOutside = (e) => {
    if (
      !e.target.classList.contains("custom-select-option") &&
      !e.target.classList.contains("selected-text")
    ) {
      setShowOptionList(false);
    }
  };

  // This method handles the display of option list
  const handleListDisplay = () => {
    setShowOptionList(!showOptionList);
  };

  // This method handles the setting of name in select text area
  // and list display on selection
  const handleOptionClick = (e) => {
    setDefaultSelectText(e.target.getAttribute("data-name"));
    props.DefaultSelectText(e.target.getAttribute("data-id"));
    setShowOptionList(false);
  };
  
  return (
    <div className="custom-select-container">
      <div
        className={showOptionList ? "selected-text active" : "selected-text"}
        onClick={handleListDisplay}
      >
        {defaultSelectText}
      </div>
      {showOptionList && (
        <ul className="select-options">
          {optionsList.map((option) => {
            return (
              <li
                className="custom-select-option"
                data-name={option.name}
                data-id={option.id}
                key={option.id}
                onClick={handleOptionClick}
              >
                {option.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default SelectDropdown;
