import DateTimeRangeSelector from "../../ui/DateTimeRangeSelector";
import FormIdentifier from "../../ui/FormIdentifier";
import SelectDropdown from "../../ui/SelectDropdown";
import {post,get} from "../../api/apiCalls";

import { GrClose } from "react-icons/gr";
import "./ManualFormModal.css";
import { ReactSession } from 'react-client-session';
import { useState,useMemo } from "react";
import Alert from 'react-bootstrap/Alert';

function OvertimeFormModal(props) {
  let [DefaultProjectSelectText,setProjectText] = useState("Please Select Project");
  let [DefaultReasonSelectText,setReasonText] = useState("Please Select Reason");

  let [data,setData] = useState(0);
  useMemo(() => {
    getProjectList();
    // componentWillMount events
  },[]);
  function getProjectList() {
    const getProjects = 
    async () => { 
      
      let user = ReactSession.get("User");
      let rsummary = await get("Projects/GetProjectsForAgent?userID="+user.id);
      
      let list = [];
      for(let i = 0 ; i < rsummary.length ; i++ ) {
        list.push( {
            id : rsummary[i].id,
            name : rsummary[i].name,
          });
      }
      
      
      setData(list);
      
      
    }
    getProjects();
  }

  const ReasonsLists = [
    { id: 1, name: "Planned Extended shift" },
    { id: 2, name: "Planned Weekend shift" },
    { id: 3, name: "Requested by the customer" },
    { id: 4, name: "Not planned additional hours" },
    { id: 5, name: "Other" },
  ];
  let [fromDate,setFromDate] = useState(0);
  let [toDate,setToDate] = useState(0);

  ReactSession.set("MR","");
  function addRequest() {
    let mto =  {'project' : DefaultProjectSelectText,
    'reason' : ReasonsLists[DefaultReasonSelectText-1].name,
    'from' : fromDate,
    'to' : toDate
  }
  

    props.setMto(mto);
  }

  return (
    <div className="form-modal overtime-modal">
      <h4 className="modal-title">Request Overtime Form</h4>
    <div className="Timeform">
      <Alert variant="success">
        This feature will allow you to extend your project weekly hour cap.<br/>It will extend the maximum hours counted toward your payroll time.<br/>It will not add time to your total time. 
        <br/>Example: Cap time is 40 hrs, with OT of 4 hrs, your maximum payroll time will be 44hr.
        <br/>Please be mindful that those additional 4 hrs still need to be tracked.
        <br/>Please select time according to your timezone.
      </Alert>
      <div className="manual-form-modal">
      
        <DateTimeRangeSelector
          setFromDate = {setFromDate}
          setToDate = {setToDate}
          startplaceholderText="Select Start Date Time"
          endplaceholderText="Select End Date Time"
          forward={30}
          backward={0}
          filterTime={false}
        />
      </div>
      <div className="manual-form-modal">
        <SelectDropdown
          DefaultSelectText={setProjectText}
          OptionsLists={data}
        />
        <SelectDropdown
          DefaultSelectText={setReasonText}
          OptionsLists={ReasonsLists}
        />
      </div>
      <div className="overtime-modal-btns">
        <button className="create-overtime-btn" onClick={addRequest}>Create</button>
        <button className="cancel-overtime-btn" onClick={props.onCancel}>Cancel</button>
      </div>
    </div>
  </div>
  );
}

export default OvertimeFormModal;
