import React, { useState,useMemo } from "react";
import TimezoneSelect from "react-timezone-select";
// import { FaGlobe } from "react-icons/fa";

import "./TimezoneSelector.css";

function TimezoneSelector(props) {
  const [tz, setTz] = useState(
    null
    
  );
  let setTimezone = (e) => {
    if(props.setTimeOffset !==undefined)
      props.setTimeOffset(e.offset);
    setTz(e);
  }

  useMemo(() => {
      setTz("Etc/GMT");
  },[]);

  return (
    <div className="timezoneSelector">
      <TimezoneSelect value={tz} onChange={setTimezone} />
      {/* <FaGlobe className="FaIcon" />*/}
    </div>
  );
}

export default TimezoneSelector;


