import SubHeaderLinks from "../../layout/header/SubHeaderLinks";

import "../../elements/diaryElements/DiaryHours.css";
import "./Diary.css";
import DateSelector from "../../ui/DateSelector";
import moment from 'moment';
import React, { useState,useMemo, useEffect} from 'react';
import { ReactSession } from 'react-client-session';
import { newPost, get } from "../../api/apiCalls";
import DiaryTimeLine from "../../elements/diaryElements/DiaryTimeline";
import ConfirmDeleteModal from "../../ui/ConfirmDeleteModal";
import ConfirmActivateModal from "../../ui/ConfirmActivateModal";
import Backdrop from "../../ui/Backdrop";
import TimezoneSelector from "../../ui/TimezoneSelector";
import LoadingSpinner from '../../elements/UiElements/LoadingSpinner'

function Diary(props) {
  let [lastDate, setdate] = useState(moment().format("YYYY-MM-DD"));
  let [timeOffset,setTimeOffset] = useState(0);
  let [summary, setSummary] = useState(0);
  let [loading,setLoading] = useState(false);
  const [isManager, setIsManager]= useState(false);
  let[loadingImages, setLoadingImages]= useState(true);
  const [items, setitems] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmActivate, setConfirmActivate] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [isManuallySelected, setIsManuallySelected] = useState(false);

  function checkinput(items) {
    let count = items.length;
    
    if (count < 1) {
      document.getElementById("delete-cards").style.display = "none";
      document.getElementById("active-cards").style.display = "none";
    } else {
      document.getElementById("delete-cards").style.display = "block";
      document.getElementById("active-cards").style.display = "block";
    }
    setitems(items);
  }

  useEffect(() => {
    if (isManuallySelected) return;

    const checkboxes = document.querySelectorAll('.cards-checkboxes');
    if (selectAll) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
      setSelectedBoxes(Array.from(checkboxes).map((checkbox) => checkbox.id));
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      setSelectedBoxes([]);
    }
  }, [selectAll, isManuallySelected]);

  const handleSelectAll = () => {
    setIsManuallySelected(false);  // Indica que a seleção foi feita pelo botão Select All
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };


  function deleteHandler() {
    setConfirmDelete(true);
  }

  function activateHandler() {
    setConfirmActivate(true);
  }

  function CloseModal() {
    setConfirmActivate(false);
    setConfirmDelete(false);
    setitems([]);
  }

  function ConfirmDeleteHandler() {
    deleteCards();
    setConfirmActivate(false);
    setConfirmDelete(false);
  }

  function ConfirmActivateHandler() {
    markCardActive();
    setConfirmActivate(false);
    setConfirmDelete(false);
  }

  let markCardActive = async()  => {
    
    let checked = document.querySelectorAll('input:checked');
    let num = [];
    for (const item of checked) {
      num.push(parseInt(item.id));     
      item.checked = false;
    }

    let keys = {
      cardsID : JSON.stringify(num),
      id : user.id
    };

    await newPost("TrackingDataAudit/MarkCardsAsActive",keys).then(response => {
      getSummaryDetails();
    });

    setSelectAll(false)

  }

  let deleteCards = async()  => {
    
    let checked = document.querySelectorAll('input:checked');
    let num = [];
    for (const item of checked) {
      num.push(parseInt(item.id));
      item.checked = false;  
    }
    let keys = {
      cardsID : JSON.stringify(num),
      id : user.id
    };
    await newPost("TrackingDataAudit/DeleteCards",keys).then(response => {
      getSummaryDetails();
    });

    setSelectAll(false)
  }
  //End of mark/delete
  const setDiaryDate = (date) => {
    setdate(date);
    lastDate = date;
    getSummaryDetails();
  };

  const getSummaryDetails = async (offset) => {
		setLoading(true);
		const keys = {
		  date: moment(lastDate).format("YYYY-MM-DD"),
		  id: user.id,
      offset
		};
	  
		let summary = await fetchSummaryData(keys);
		if (summary === 0 ) {
      setLoading(false);
      setLoadingImages(false)
		} else {
      setSummary(summary);
      setLoading(false);
      setLoadingImages(false)
		}
	  
	};
  

	const fetchSummaryData = async (keys) => {
		try {
		  const response = await get("TrackingData/DiaryUserTrackingData", keys);
		  return response;
		} catch (error) {
		  console.error("Error fetching summary data:", error);
		  return undefined;
		}
	};
  

  const handleSelection = (state, id) => {
    setSelectedBoxes((prevSelectedBoxes) => {
      const updatedSelectedBoxes = state
        ? [...prevSelectedBoxes, id]
        : prevSelectedBoxes.filter((boxId) => boxId !== id);

      setIsManuallySelected(true); 

      if (updatedSelectedBoxes.length === document.querySelectorAll('.cards-checkboxes').length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }

      return updatedSelectedBoxes;
    });
  };

  let user = ReactSession.get("User");

  useMemo(async () => {
    if(summary===0)
      await getSummaryDetails();
    if(user.role === 1 || user.role === 3){
      setIsManager(true)
    } 
    // componentWillMount events
  },[]);
  
  let changeTimeOffset = (Offset) => {
      timeOffset = Offset
      console.log("OFFSET: ", timeOffset)
      getSummaryDetails(timeOffset);
  }

  if(summary.length <= 0){
    return(<div className="diary">
      {/* <SearchSection /> */}
      <div>
        <SubHeaderLinks />
        <div className="diaryMain step-0">
          <div className="filters">
            
            <span>Diary</span>
            <DateSelector placeholderText={moment().format("YYYY-MM-DD")} updateTimers={setDiaryDate} />
            <span>Timezone</span>
            <TimezoneSelector setTimeOffset={changeTimeOffset} value={'Etc/GMT'} />
            {loading && <LoadingSpinner />}
          </div>
          {!loading && <p className="no-data-msg">No timecards for the selected day</p>}
        </div>
      </div>
    </div>
                

    );

    
  }
 

  return (
    <div className="diary">
      {/* <SearchSection /> */}
      <div>
        <SubHeaderLinks />
        <div className="diaryMain step-0">
          <div className="filters">
           
            <span>Diary</span>
            <DateSelector placeholderText={moment().format("YYYY-MM-DD")} updateTimers={setDiaryDate} />
            <span>Timezone</span>
            <TimezoneSelector setTimeOffset={changeTimeOffset} />
            {loading && <LoadingSpinner />}
          </div>
          {!loadingImages && (
            <div className="diary-cards-box">
              <h4 className="diary-cards-title">Diary cards</h4>
              {!user.disabled && summary.length > 0 &&(
                <div className="timecard-action-box">
                  <button
                    className="timecards-action-btns"
                    id="select-all-cards"
                    onClick={handleSelectAll}
                  >
                    {selectedBoxes.length <= 0 ? (
                      <>
                        Select All
                      </>

                    ): (
                      <>
                        Clear Selection
                      </>
                    )}
                  </button>
                  <button
                    className="timecards-action-btns"
                    id="delete-cards"
                    disabled={selectedBoxes.length <= 0}
                    onClick={deleteHandler}
                  >
                    Delete Time Cards
                  </button>
                  <button
                    className="timecards-action-btns"
                    id="active-cards"
                    disabled={selectedBoxes.length <= 0}
                    onClick={activateHandler}
                  >
                    Mark as Active
                  </button>
                </div>
              )}
              <DiaryTimeLine
                handleSelection={handleSelection}
                data = {summary}
              />
            </div>
          )}
         
        </div>
      </div>
      {confirmDelete && (
        <ConfirmDeleteModal
          items={items}
          ConfirmDelete={ConfirmDeleteHandler}
          onCancel={CloseModal}
        />
      )}
      {confirmActivate && (
        <ConfirmActivateModal
          items={items}
          ConfirmActivate={ConfirmActivateHandler}
          onCancel={CloseModal}
        />
      )}
      {confirmDelete && <Backdrop onCancel={CloseModal} />}
      {confirmActivate && <Backdrop onCancel={CloseModal} />}
    </div>
    
  );
}

export default Diary;
