import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { BiCalendar } from "react-icons/bi";

import "react-datepicker/dist/react-datepicker.css";

import "./DateSelector.css";
import "./DateRangeSelector.css";

function DateRangeSelector(props) {
  const [isDailyActive, setDailyActive] = useState(true);
  const [isWeeklyActive, setWeeklyActive] = useState(false);
  const [isMonthlyActive, setMonthlyActive] = useState(false);
  const [isCustomActive, setCustomActive] = useState(false);

  let StartDate = new Date();
  let EndDate = new Date();

  const [startDate, setStartDate] = useState(StartDate);
  const [endDate, setEndDate] = useState(EndDate);

  function setFromDate(date) {
    setStartDate(date);
    props.onFromChangeDate(date);
  }

  function setToDate(date) {
    setEndDate(date);
    props.onToChangeDate(date);
  }

  const toggleDailyClass = () => {
    setDailyActive(true);
    setWeeklyActive(false);
    setMonthlyActive(false);
    setCustomActive(false);
    setFromDate(new Date());
    setToDate(new Date());
  };

  const toggleWeeklyClass = () => {
    setDailyActive(false);
    setWeeklyActive(true);
    setMonthlyActive(false);
    setCustomActive(false);
    let curr = new Date();
    let first;
    if (curr.getDay() == 0) {
      first = curr.getDate() - 6;
    } else {
      first = curr.getDate() - curr.getDay() + 1;
    }
    StartDate = new Date(curr.setDate(first));
    EndDate = new Date();
    setFromDate(StartDate);
    setToDate(EndDate);
  };

  const toggleMonthlyClass = () => {
    setDailyActive(false);
    setWeeklyActive(false);
    setMonthlyActive(true);
    setCustomActive(false);
    let curr = new Date();
    StartDate = new Date(curr.setDate(1));
    EndDate = new Date();
    setFromDate(StartDate);
    setToDate(EndDate);
  };

  const toggleCustomClass = () => {
    setDailyActive(false);
    setWeeklyActive(false);
    setMonthlyActive(false);
    setCustomActive(true);
    StartDate = new Date();
    EndDate = new Date();
    setFromDate(StartDate);
    setToDate(EndDate);
  };

  const CalendarStartDateSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="calendar_date" onClick={onClick} ref={ref}>
      {startDate ? value : "From Date"}
      <BiCalendar className="calendar_icon" />
    </div>
  ));

  const CalendarEndDateSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="calendar_date" onClick={onClick} ref={ref}>
      {startDate ? value : "To Date"}
      <BiCalendar className="calendar_icon" />
    </div>
  ));

  return (
    <div className="date-range">
      <div className="time-filters">
        <span
          className={isDailyActive ? "active-filter" : null}
          onClick={toggleDailyClass}
        >
          Daily
        </span>
        <span
          className={isWeeklyActive ? "active-filter" : null}
          onClick={toggleWeeklyClass}
        >
          Weekly
        </span>
        <span
          className={isMonthlyActive ? "active-filter" : null}
          onClick={toggleMonthlyClass}
        >
          Monthly
        </span>
      </div>
      <div className="range-selectors">
        <div className="dateSelector">
          <DatePicker
            selected={startDate}
            onChange={(date) => setFromDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            className="no-border"
            calendarStartDay={1}
            customInput={<CalendarStartDateSelector />}
          />
        </div>
        <div className="dateSelector">
          <DatePicker
            selected={endDate}
            onChange={(date) => setToDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            className="no-border"
            calendarStartDay={1}
            customInput={<CalendarEndDateSelector />}
          />
        </div>
      </div>
    </div>
  );
}

export default DateRangeSelector;
