import Header from "../components/layout/header/Header.js";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar.js";
import DashboardManualTimeApproval from "../components/manager/teamDashboards/DashboardManualTimeApproval.js";

function ManagerManualTimeApproval() {
  return (
    <>
      <Header pageName="Manager Dashboard" />
      <ManagerSidebar />
      <DashboardManualTimeApproval />
    </>
  );
}

export default ManagerManualTimeApproval;
