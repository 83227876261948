import { useState } from "react";
import "./TextInput.css";

function UserTextInput(props) {
  const [enteredInput, setEnteredInput] = useState(props.value);
  const InputChange = (e) => {
    setEnteredInput(e.target.value);
    props.onChangeText(e.target.value);
  };

  return (
    <div>
        <span>{props.text}</span>
          <div className="input-block">
            <input
            className="input-text"
            disabled
            type={props.type}
            required={props.required}
            value={props.value }
            onChange={InputChange}
            />
        </div>
    </div>
  );
}

export default UserTextInput;
