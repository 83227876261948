import WisdomLogo from "../../../images/Wisdom logo transparent.png"
import { Link } from "react-router-dom";
import "./Landing.css";

function LandingHeader() {
  return (
    <div className="landing-header">
      <img className="landing-logo" src={WisdomLogo} alt="Wisdom Logo" />
      <div className="landing-header-links">
        <Link className="landing-header-link" to="/signin">
          Sign In
        </Link>
      </div>
    </div>
  );
}

export default LandingHeader;
