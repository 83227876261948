import Header from "../components/layout/header/Header";
import Sidebar from "../components/layout/sidebar/Sidebar";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import AuditDetails from "../components/manager/dashboard/AuditDetails";
function Audit() {
  return (
    <div>
      <Header pageName="Dashboard" />
      <ManagerSidebar />
      <AuditDetails />
    </div>
  );
}

export default Audit;
