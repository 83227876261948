import { useState } from "react";
import "./TextInput.css";

function TextInput(props) {
  const [enteredInput, setEnteredInput] = useState(props.value);
  const InputChange = (e) => {
    setEnteredInput(e.target.value);
    props.onChangeText(e.target.value);
  };

  return (
    <div>
      <span>{props.text}</span>
      <div className="input-block">
        <input
          className="input-text"
          disabled={props.disabled}
          // placeholder={props.text}
          type={props.type}
          required={props.required}
          value={enteredInput == null || enteredInput == undefined? props.value : enteredInput }
          onChange={InputChange}
        />
      </div>
    </div>
  );
}

export default TextInput;
