import UserRole from "../components/admin/UserRole";
import Header from "../components/layout/header/Header";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";

export function AdminUserRole(){

    return(
        <div>
            <Header pageName="Dashboard" />
            <ManagerSidebar />
            <UserRole />
        </div>
    )
}