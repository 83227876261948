import DateTimeRangeSelector from "../../ui/DateTimeRangeSelector";
import FormIdentifier from "../../ui/FormIdentifier";
import SelectDropdown from "../../ui/SelectDropdown";
import {post,get} from "../../api/apiCalls";

import { GrClose } from "react-icons/gr";
import "./ManualFormModal.css";
import { ReactSession } from 'react-client-session';
import { useState,useMemo } from "react";
import Alert from 'react-bootstrap/Alert';

function ManualFormModal(props) {
  let [DefaultProjectSelectText,setProjectText] = useState("Please Select Project");
  let [DefaultReasonSelectText,setReasonText] = useState("Please Select Reason");

  let [data,setData] = useState(0);
  useMemo(() => {
    getProjectList();
    // componentWillMount events
  },[]);
  function getProjectList() {
    const getProjects = 
    async () => { 
      
      let user = ReactSession.get("User");
      let rsummary = await get("Projects/GetProjectsForAgent?userID="+user.id);
      
      let list = [];
      for(let i = 0 ; i < rsummary.length ; i++ ) {
        list.push( {
            id : rsummary[i].id,
            name : rsummary[i].name,
         
          });
          
        
      }
      
      
      setData(list);
      
      
    }
    getProjects();
  }

  const ReasonsLists = [
    { id: 1, name: "Tracking Tool Lost Time" },
    { id: 2, name: "Working on MacOS" },
    { id: 3, name: "Working on Linux" },
    { id: 4, name: "Forgot to turn on tracker" },
    { id: 5, name: "Other" },
  ];
  let [fromDate,setFromDate] = useState(0);
  let [toDate,setToDate] = useState(0);

  ReactSession.set("MR","");
  function addRequest() {
    let mto =  {'project' : DefaultProjectSelectText,
    'reason' : ReasonsLists[DefaultReasonSelectText-1].name,
    'from' : fromDate,
    'to' : toDate
  }
  

    props.setMto(mto);
  }

  return (
    <div className="form-modal manualtime-modal">
    <h4 className="modal-title">Request Manual Time Form</h4>
    <div className="Timeform">
      <Alert variant="info">
        This feature allows you to add workhours to your timesheets, should any issues arise with the Tracker.
        <br/>For instance, if you forgot to turn on the tracker or if you have an OS that is not supported. 
        <br/><strong>MT can only be entered 7 days in the past</strong>, and please select the time based on your timezone. 
        <br/>If you have any questions, please reach out to your manager.
      </Alert>
      <div className="manual-form-modal">
        <DateTimeRangeSelector
          setFromDate = {setFromDate}
          setToDate = {setToDate}
          startplaceholderText="Select Start Date Time"
          endplaceholderText="Select End Date Time"
          forward={0}
          backward={7}
          filterTime={true}
        />
      </div>
      <div className="manual-form-modal">
        <SelectDropdown
          DefaultSelectText={setProjectText}
          OptionsLists={data}
        />
        <SelectDropdown
          DefaultSelectText={setReasonText}
          OptionsLists={ReasonsLists}
        />
      </div>
      <div className="mt-form-btns">
        <button onClick={addRequest} className="create-mt-btn">Create</button>
        <button onClick={props.onCancel} className="cancel-mt-btn">Cancel</button>
      </div>
    </div>
  </div>
  );
}

export default ManualFormModal;
