import TrolleySetup from "../components/admin/TrolleySetup"
import Header from "../components/layout/header/Header";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";


function AdminTrolleyReport() {
  return (
    <div>
      <Header pageName="Dashboard" />
      <ManagerSidebar />
      <TrolleySetup />
    </div>
  );
}

export default AdminTrolleyReport;
