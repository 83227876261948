import "./Header.css";
import Logo from "./Logo";
import Profile from "./Profile";
import DownloadButton from "../../elements/DownloadButton";
import { ReactSession } from 'react-client-session';
import Tracker from "../../elements/tracker/Tracker";


function Header(props) {
  let user = ReactSession.get("User");

  return (
    <div className="header">
      <div className="align-left">
        <Logo />
        <div className="pageName">{props.pageName}</div>
      </div>
      <div className="align-right">
        {user.webtracker_enabled &&(
          <Tracker />
        )}
        <div className="download-button">
        {user.role !=4 && !user.disabled  && (
          <DownloadButton />
          )}
        </div>
        <Profile />
      </div>
    </div>
  );
}

export default Header;
