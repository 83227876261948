import "./Landing.css";
import { Link } from "react-router-dom";
import imageRight from "../../../images/ImageRight.png";

function LandingCenter() {
  return (
    <div className="landing-center">
      <div className="landing-center-text">
        <div className="landing-center-text-header">
          Get the Most out of Your Employees’ Time
        </div>
        <div className="landing-text">
          Employee tracking software that tells you exactly how your employees
          use their time. Get powerful data for a more productive and efficient
          team.
        </div>
        <Link to="/signin" className="landing-center-button">
          Start Tracking Now
        </Link>
      </div>
      <img
        className="landing-center-image-right"
        src={imageRight}
        alt="Wisdom Features"
        />
    </div>
  );
}

export default LandingCenter;
