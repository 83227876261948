import "./EmployeeSummary.css";
import SummaryCard from "../../ui/SummaryCard";
import DateRangeSelector from "../../ui/DateRangeSelector";
import SummaryChartCard from "../../elements/summaryElements/SummaryChartCard";
import SubHeaderLinks from "../../layout/header/SubHeaderLinks";
import EmployeeShortProfile from "../../elements/summaryElements/EmployeeShortProfile";
import { ReactSession } from 'react-client-session';
import { get } from "../../api/apiCalls";
import React, { useState,useMemo  } from 'react';
import Joyride from "react-joyride"
import moment from 'moment';
import useProfileImage from "../../../hooks/useProfileImage";

function Summary() {
  const [TotalTime, setTotalTime] = useState(0);
  const [ActiveTime, setActiveTime] = useState(0);
  const [IdleTime, setIdleTime] = useState(0);
  const [ManualTime, setManualTime] = useState(0);
  const [Overtime, setOvertime] = useState(0);
  const [announcement, setAnnouncement] = useState();
  const userImage = useProfileImage();
  let [from, setFrom] = useState(0);
  let [to, setTo] = useState(0);
  const [productivityRate, setProductivityRate] = useState(0);

  const FirstAnnounce = () => {
    return(
      <div>
        <p>{announcement}</p>
      </div>
    )
  }
  const steps = [
    {
      target: 'body',
      content: <FirstAnnounce />,
      placement: "center",
      title: <h2 className="joyride-title"> Important News</h2>
    }
  ]
  let user = ReactSession.get("User");
  
  const getSummaryDetails = async () => {
    let start = moment(from).format("YYYY-MM-DD")
    let end = moment(to).format("YYYY-MM-DD")
    summary = await get(`Reports/GetUserSummary?from=${start}&to=${end}&userID=${user.id}`);
    setTotalTime(summary.totalTime);
    setActiveTime(summary.activeTime);
    setIdleTime(summary.idleTime);
    setManualTime(summary.requested);
    setOvertime(summary.overtime)
    setProductivityRate(parseFloat(summary.activeTime/summary.totalTime).toFixed(1)*100);
  
  };

  const getAnnouncement = async() => {
    let announcement = sessionStorage.getItem('announcement')
    if(announcement === null || announcement === undefined){
      let response = await get("Announcements")
      let announce = response[0].announcement
      setAnnouncement(announce)
      sessionStorage.setItem('announcement', announce)
    }else{
      setAnnouncement(announcement)
    }
  }

  let summary;// = getSummaryDetails();
  const setFromDate = (date) => {
    setFrom(date);
    from = date;
    getSummaryDetails();
  };

  const setToDate = (date) => {
    setTo(date);
    to=date;
    getSummaryDetails();
  };
  useMemo(() => {
    let date = moment();
    setFrom(date);
    setTo(date);
    from = date;
    to = date;
    getSummaryDetails();
    getAnnouncement();
  },[]);
  
  return (
    <div className="summary">
      {!user.isFirstLogin &&(
        <Joyride
          steps={steps}
          styles={{
            options: {
              zIndex: 9999,
            }
          }}
        />
      )}
      <div className="summaryMain">
        <div className="my-dashboard-header">
          <EmployeeShortProfile
            employeeName={user.firstName + ' ' + user.lastname}
            employeePronoun={user.pronoun}
            employeeDesignation={user.jobDescription}
            employeeID={user.id}
            employeeUsername={user.username}
            employeeLocation={user.city}
            employeePicture={userImage}
          />
          <SubHeaderLinks />
        </div>
        <div className="summaryDiary">
          <div className="summary-details" id="step-1">
            <div className="summaryHeader" >{} Diary</div>
            <div className="selectors-row">
              <div className="selectors-column">
                <div className="selectors">
                  <DateRangeSelector
                    onFromChangeDate = {setFromDate}
                    onToChangeDate = {setToDate}  />
                  {/* <DateSelector placeholderText="From Date" updateTimers={setFromDate} />
                  <DateSelector placeholderText="To Date" updateTimers={setToDate} /> */}
                <p className="timezone-alert">All time in the summary is on the UTC timezone</p>
                </div>
                <div className="summaryCards">
                  <SummaryCard title="Tracked Time" cardtime={TotalTime} />
                  <SummaryCard title="Idle Time" cardtime={IdleTime} />
                  <SummaryCard title="Manual Time" cardtime={ManualTime} />
                  <SummaryCard title="Productive Time" cardtime={ActiveTime} />
                  <SummaryCard title="Overtime" cardtime={Overtime} />
                </div>
              </div>
                <SummaryChartCard
                  chartTitle="Productivity Score"
                  Productive={ActiveTime}
                  Idle={IdleTime}
                  Manual={ManualTime}
                  Total={TotalTime}
                  productivityRate={productivityRate}
                 />
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
