import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { isSameISOWeek, endOfWeek, startOfWeek } from "date-fns";
import { BiCalendar } from "react-icons/bi";

import "react-datepicker/dist/react-datepicker.css";
import "./DateSelector.css";
import "./DateRangeSelector.css";

function WeekRangeSelector(props) {
  let StartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  let EndDate = endOfWeek(new Date(), { weekStartsOn: 1 });

  const [startDate, setStartDate] = useState(StartDate);
  const [endDate, setEndDate] = useState(EndDate);

  const CalendarStartDateSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="calendar_date" onClick={onClick} ref={ref}>
      {startDate ? value : "From Date"}
      <BiCalendar className="calendar_icon" />
    </div>
  ));

  const CalendarEndDateSelector = forwardRef(({ value, onClick }, ref) => (
    <div className="calendar_date" onClick={onClick} ref={ref}>
      {startDate ? value : "To Date"}
      <BiCalendar className="calendar_icon" />
    </div>
  ));

  return (
    <div className="date-range">
      <div className="range-selectors">
        <div className="weekSelector">
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(startOfWeek(date, { weekStartsOn: 1 }));
            }}
            onSelect={(date) => {
              let start = startOfWeek(date, { weekStartsOn: 1 });
              props.selectDate(start, endDate);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            className="no-border"
            calendarStartDay={1}
            showWeekNumbers
            dayClassName={(date) =>
              isSameISOWeek(date, StartDate, { weekStartsOn: 1 })
                ? "react-datepicker__day--selected"
                : ""
            }
            customInput={<CalendarStartDateSelector />}
          />
        </div>
        <div className="weekSelector">
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              setEndDate(endOfWeek(date, { weekStartsOn: 1 }));
            }}
            onSelect={(date) => {
              let end = endOfWeek(date, { weekStartsOn: 1 });
              props.selectDate(startDate, end);
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            className="no-border"
            calendarStartDay={1}
            showWeekNumbers
            dayClassName={(date) =>
              isSameISOWeek(date, endDate, { weekStartsOn: 1 })
                ? "react-datepicker__day--selected"
                : ""
            }
            customInput={<CalendarEndDateSelector />}
          />
        </div>
      </div>
    </div>
  );
}

export default WeekRangeSelector;
