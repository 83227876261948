import { BrowserRouter as Router, Route, Routes, Navigate  } from "react-router-dom";
import Audit from "./pages/Audit";
import LandingPage from "./pages/LandingPage";
import SignInPage from "./pages/SignInPage";
import EmployeeSummaryDashboard from "./pages/EmployeeSummaryDashboard";
import EmployeeDiary from "./pages/EmployeeDiary";
import EmployeeProfile from "./pages/EmployeeProfile";
import ManagerDashboard from "./pages/ManagerDashboard";
import ManagerProjectsDashboard from './pages/ManagerProjectsDashboard';
import UserSummaryDashboard from "./pages/UserSummaryDashboard";
import UserDiary from "./pages/UserDiary";
import UserProfile from "./pages/UserProfile";
import UserOvertime from "./pages/UserOvertime";
import UsersRequest from "./pages/UsersRequests"
import ManagerPayrollFullDetails from "./pages/ManagerPayrollFullDetails";
import TeamDashboard from "./pages/TeamDashboard";
import TeamMemberDashboard from "./pages/TeamMemberDashboard"
import React, { useMemo } from 'react';
import { ReactSession } from 'react-client-session';
import Settings from "./pages/Settings";
import PersonalInfo from "./pages/PersonalInfo";
import AdminTrolleyReport from "./pages/AdminTrolleyReport";
import AdminUpdateRate from "./pages/AdminUpdateRate";
import AdminAdditionalPayments from "./pages/AdminAdditionalPayments";
import AdditionalPayments from "./pages/AdditionalPayments";
import ManagerManualTimeApproval from "./pages/ManagerManualTimeApproval";
import AdminAnnouncements from "./pages/AdminAnnouncements";
import AdminWebTracker from "./pages/AdminWebTracker";
import { post, put } from "./components/api/apiCalls"; 
import ExtraLoginModal from "./components/manager/managerSignIn/ExtraLoginModal";
import AdminExtraLoginModal from "./components/admin/AdminExtraLoginModal";
import { AdminUserRole } from "./pages/AdminUserRole";
import { PrimeReactProvider } from "primereact/api"
import UserPersonalInfo from "./components/employee/profile/PersonalInfo"
import WorkInfo from "./components/employee/profile/WorkInfo";
import Requests from "./components/employee/profile/Requests";
import Payment from "./components/employee/profile/Payment";
import "primereact/resources/themes/lara-light-cyan/theme.css";

function App() {
  let isManager = false
  let isAdmin = false
  let isCustomer = false

  let tracker = {
    online:false,
    projectId:0,
    project:'default',
    weeklyTime: 0,
    dailyTime: 0
  }
  ReactSession.set("Tracker", tracker)
  let user;
  useMemo(() => {
    user = ReactSession.get("User");

    window.addEventListener('beforeunload', async function(event) {
      let tracker = ReactSession.get("Tracker")
      let loggedUser = ReactSession.get("User")
      if(tracker.online){
        event.preventDefault();
        
        let key = {
            jobId: sessionStorage.getItem("jobID"),
            username: loggedUser.username
        }
        let keys = {
          ID: parseInt(sessionStorage.getItem('session')),
          Online: false
        }
        await put('DesktopApp/Online', keys)
        let response = await post("WebTracker/RemoveJob", key)
        if(response){
            tracker.online = false
            ReactSession.set("Tracker", tracker)
            alert("Your web tracker was set to offline, please turn it on again")
            window.location.reload()
        }

        return 'Are you sure you want to leave?';
      }
    });
    if(user !== undefined){
      if(user.role === 1) isAdmin = true
      if(user.role === 3 || user.role === 1) isManager = true
      if(user.role === 4) isCustomer = true
    }
  },[]);
  
  return (
    <PrimeReactProvider>
      <div>
        {user !== undefined ? (
          <Routes>
            <Route path="/summary" element={<EmployeeSummaryDashboard />} />
            <Route path="/diary" element={<EmployeeDiary />} />
            <Route path="/profile" element={<EmployeeProfile />} />
            <Route path="/profile/info" element={<UserPersonalInfo />} />
            <Route path="/profile/requests" element={<Requests />}/>
            <Route path="/profile/payment" element={<Payment />}/>
            <Route path="/profile/work" element={<WorkInfo />} />
            <Route path="/manager" element={isManager ? <ManagerDashboard /> : <Navigate to="/summary" />} />
            <Route path="/team" element={isCustomer ? <TeamDashboard /> : <Navigate to="/summary" />} />
            <Route path="/team/dashboard/:employeeId" element={<TeamMemberDashboard />} />
            <Route path="/manager/payrolldetails" element={isManager ? <ManagerPayrollFullDetails /> : <Navigate to="/summary" />} />
            <Route path="/manager/audit" element={isManager ? <Audit /> : <Navigate to="/summary" />} />
            <Route path="/manager/additional-payments" element={isManager ? <AdditionalPayments /> : <Navigate to="/summary" />} />
            <Route path="/manager/summary/:employeeId" element={isManager ? <UserSummaryDashboard /> : <Navigate to="/summary" />} />
            <Route path="/manager/personalInfo/:employeeId" element={isManager ? <PersonalInfo /> : <Navigate to="/summary" />} />
            <Route path="/manager/diary/:employeeId" element={isManager ? <UserDiary /> : <Navigate to="/summary" />} />
            <Route path="/manager/user-requests/:employeeId" element={isManager ? <UsersRequest /> : <Navigate to="/summary" />} />
            <Route path="/manager/projects" element={isManager ? <ManagerProjectsDashboard /> : <Navigate to="/summary" />} />
            <Route path="/manager/manual-time-approval" element={isManager ? <ManagerManualTimeApproval /> : <Navigate to="/summary" />} />
            <Route path="/settings/sso-setup" element={isAdmin ? <Settings /> : <Navigate to="/summary" />} />
            <Route path="/user-settings/trolley-report" element={isAdmin ? <AdminTrolleyReport /> : <Navigate to="/summary" />} />
            <Route path="/user-settings/update-rate" element={isAdmin ? <AdminUpdateRate /> : <Navigate to="/summary" />} />
            <Route path="/user-settings/user-role" element={isAdmin ? <AdminUserRole /> : <Navigate to="/summary" />} />
            <Route path="/user-settings/additional-payments" element={isAdmin ? <AdminAdditionalPayments /> : <Navigate to="/summary" />} />
            <Route path="/settings/announcements" element={isAdmin ? <AdminAnnouncements /> : <Navigate to="/summary" />} />
            <Route path="/settings/web-tracker" element={isAdmin ? <AdminWebTracker /> : <Navigate to="/summary" />} />
            <Route path="/*" element={<Navigate to="/summary" />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/manager/manual/:employeeId" element={user === undefined ? <ExtraLoginModal /> : <UserProfile />} />
            <Route path="/manager/overtime/:employeeId" element={user === undefined ? <ExtraLoginModal /> : <UserOvertime />} />
            <Route path="/settings/additional-payments" element={user === undefined ? <AdminExtraLoginModal /> : <AdminAdditionalPayments />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}

      </div>
    </PrimeReactProvider>
  );
  
}

export default App;
