import "../../ui/TimezoneSelector.css";
import "./PersonalInfo.css"
import { useState , useMemo } from "react";
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import { post } from "../../api/apiCalls";
import { useParams } from "react-router-dom";
import moment from "moment";
import UserTextInput from "../../ui/UserTextInput";

function UserPersonalInfo(props) {
  const params = useParams();
  let [euser,setUser] = useState([]);

  const getUser = async() => {
    let keys = {
      id : params.employeeId
    };
    let u = await post("Users/GetUserById",keys);
    console.log("EUSER: ", u)
    setUser(u);
    
  }
  
  
  useMemo(() => {
    getUser();
    
    // componentWillMount events
  },[]);
  return (
    <div className="summary">
      <div className="employee-main-content">
      <ManagerSubHeaderLinks id={params.employeeId}/>
        <div className="personal-info-header">Details</div>
        
        <form className="personal-info-summary">
          <UserTextInput
            text="First Name"
            type="text"
            required="required"
            value={euser.firstName}
        
            define={"firstName"}
          />
          <UserTextInput
            text="Last Name"
            type="text"
            required="required"

            value={euser.lastname}
          />
          <UserTextInput
            text="Email"
            type="email"
            required="required"

            value={euser.username}
          />
          <UserTextInput
            text="Job Title"
            type="text"
            required="required"
            value={euser.job_Description}
          />

          <UserTextInput
            text="Phone"
            type="text"

            value={euser.phone}
          />
          <UserTextInput
            text="Preferred Pronoun"
            type="text"
            required="required"

            value={euser.pronoun}
          />
          <UserTextInput
            text="Birthday - mm/dd/yyyy"
            type="text"
            required="required"

            value={moment(euser.birthday).format('LL')}
          />
          <UserTextInput
            text="City / Country"
            type="text"
            required="required"

            value={euser.city}
          />
          <UserTextInput text="Emergency Contact" 
            type="text" 
            value={euser.emergency_Contact}
            required="required"
            />
          
          <UserTextInput
            text="Personal Email"
            type="text"
            required="required"

            value={euser.personal_Email}
          />

          <UserTextInput
            text="Contract Expire Date"
            type="text"
            required="required"

            value={euser.contract_endDate}
          />

          <UserTextInput
            text="Contract Status"
            type="text"
            required="required"

            value={euser.contract_Status}
          />

          <UserTextInput
            text="Timezone"
            type="text"
            required="required"

            value={euser.timezone}
          />
        
        </form>
        
      </div>
    </div>
  );
}

export default UserPersonalInfo;
