import { Route, Routes } from "react-router-dom";
import ProfileSubHeaderLinks from "../header/ProfileSubHeaderLinks";
import PersonalInfo from "../../employee/profile/PersonalInfo";
import WorkInfo from "../../employee/profile/WorkInfo";
import { ReactSession } from 'react-client-session'

import "./ProfileMain.css";
import Payment from "../../employee/profile/Payment";
import Requests from "../../employee/profile/Requests";

function EmployeeProfileMain() {
  let user = ReactSession.get("User")

  return (
    <div className="profileMain">
      <ProfileSubHeaderLinks />
      <Routes>
        <Route path="/profile/info" element={<PersonalInfo />} />
        {!user.disabled &&(
          <Route path="/profile/work" element={<WorkInfo />} />
        )}
        <Route path="/profile/requests" element={<Requests />} />
        <Route path="/profile/payment" element={<Payment />} />
      </Routes>
    </div>
  );
}

export default EmployeeProfileMain;
