import { useState, useEffect } from 'react';
function base64ToBlob(base64, contentType) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

function useProfileImage() {
  const [profileImageUrl, setProfileImageUrl] = useState(null);

  useEffect(() => {
    const base64Image = sessionStorage.getItem("profileImage");
    if (base64Image.startsWith('/static/media/default.')) {
      setProfileImageUrl(base64Image);
    }else{
      const blob = base64ToBlob(base64Image, 'image/jpeg');
      const url = URL.createObjectURL(blob);
      setProfileImageUrl(url);
    }
  }, []);

  return profileImageUrl;
}

export default useProfileImage;