import "./AdminSettings.css";
import "./UpdateRate.css";
import {get, put} from "../api/apiCalls";
import React, { useState, useMemo} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactSession } from "react-client-session"
import Box from '@mui/material/Box';
import DataTableBase from '../ui/DataTableBase.js';
import moment from 'moment/moment';
import { AdminUserSettingsHeaderLink } from '../layout/header/AdminUserSettingsHeaderLink.js';

function AdminAdditionalPaymentsPage() {
  const [additionalPayments, setAdditionalPayments] = useState([])
  const [user, setUser] = useState();
  let rowID, requestrowID, statusrowID;

  const getAdditionalPayments = async() => {
    let response = await get("Payments/GetAllOtherPayments")
    let payments = [];
    for (const item of response) {
      let payment = {
        submited_by: item.submited_by !=null ? item.submited_by : " ",
        id: item.id,
        userID : item.userID,
        userName: item.userName,
        userEmail: item.userEmail,
        reason: item.reason,
        amount: item.amount,
        status: item.status,
        created_at: moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
        approved_by: item.approved_by !=null ? item.approved_by : " ",
        approval_date: item.approval_date !=null ? moment(item.approval_date).format("YYYY-MM-DD HH:mm:ss") : " "
      }
      payments.push(payment);
    }
    setAdditionalPayments(payments);
  }

  const columns = [
    {
      id: 'submited_by',
      name: 'Submited By',
      selector: (row) => row.submited_by,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'userName',
      name: 'Agent Name',
      selector: (row) => row.userName,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'reason',
      name: 'Reason',
      selector: (row) => row.reason,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'amount',
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'status',
      name: "Status",
      selector: (row) => row.status,
      button: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "Approved",
          classNames: ["approved"],
        },
        {
          when: (row) => row.status == "Rejected",
          classNames: ["rejected"],
        },
        {
          when: (row) => row.status == "Cancelled",
          classNames: ["cancelled"],
        },
        {
          when: (row) => row.status == "Pending Approval",
          classNames: ["requested"],
        },
      ],
      cell: (row) => (
        (requestrowID = "request-buttons-" + row.id),
        (statusrowID = "request-status-" + row.id),
        (rowID = row.status),
        (
          <div>
            <div
              className="manual-hours-buttons"
              id={requestrowID}
              style={{ display: rowID == "Pending Approval" ? "block" : "none" }}
            >
              <button
                className="approve-button"
                onClick={() => ApproveRequest(row, requestrowID, statusrowID)}
                id={row.id}
              >
                Approve
              </button>
              <button
                className="reject-button"
                onClick={() => RejectRequest(row, requestrowID, statusrowID)}
                id={row.id}
              >
                Reject
              </button>
            </div>
            <div
              id={statusrowID}
              value={row.status}
              style={{ display: rowID == "Pending Approval" ? "none" : "block" }}
            >
              {row.status}
            </div>
          </div>
        )
      ),
      sortable: true,
      reorder: true,
    },
    {
      id: 'created_at',
      name: 'Created Date',
      selector: (row) => row.created_at,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'approved_by',
      name: 'Approved by',
      selector: (row) => row.approved_by,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'approval_date',
      name: 'Approval Date',
      selector: (row) => row.approval_date,
      sortable: true,
      reorder: true,
      wrap: true,
    },
  ]

  const ApproveRequest = (row, requestrowID, statusrowID) => {
    let approvedBy = user.firstName + " " + user.lastname
    changeStatus(row.id,"Approved", approvedBy);
    getAdditionalPayments();

  };
  const RejectRequest = (row, requestrowID, statusrowID) => {
    let rejectdBy = user.firstName + " " + user.lastname
    changeStatus(row.id,"Rejected",rejectdBy);
    getAdditionalPayments();
  };

  let changeStatus=
  async(id,status, approved_by) => {
    let keys = {
      id : id,
      status : status,
      approved_by: approved_by
    };
   await put("Payments/UpdateRequestStatus",keys);
   getAdditionalPayments();
  };

  useMemo(() => {
    getAdditionalPayments()
    let loggedUser = ReactSession.get("User")
    setUser(loggedUser)
    },[]);
  

  return (
    
    <div className='settingsContainer'>
      <AdminUserSettingsHeaderLink />
      {  
          <Box sx={{ flexGrow: 1}} className="settingsBody">
              <Grid container>
                  <Grid xs={10}>
                      <h3 className="settingsLabel">Additional Payments</h3>
                  </Grid>
                  <Grid xs={10} className="update-fields">
                      { additionalPayments && (
                        <DataTableBase
                        columns={columns}
                        data={additionalPayments}
                        tableName='Additional Payments'
                        fileName={"AdditionalPayments"}
                        />
                      )}
                  </Grid>
                  
              </Grid>
          </Box>
      }
    </div>
  );
}

export default AdminAdditionalPaymentsPage;
