import "./DashboardPayroll.css";
import WeekRangeSelector from "../../ui/WeekRangeSelector";
import { post } from "../../api/apiCalls";
import { ReactSession } from 'react-client-session';

import { useState,useMemo } from "react";
import {
  endOfWeek,
  startOfWeek,
  differenceInCalendarISOWeeks,
  getISOWeek,
} from "date-fns";
import DataTableBase from "../../ui/DataTableBase";
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";

function DashboardManagerPayrollFullDetails(props) {
  let StartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  let EndDate = endOfWeek(new Date(), { weekStartsOn: 1 });
  let [selectedDate,setSelectedDate] = useState();


  let [userPayrollData,setUserPayrollData] =  useState([]);

  let [byProject, setByProject] = useState(false);
  let [loading,setLoading] = useState(false);
  const [weekDetails, setWeekDetails] = useState(
   
  );

  

  function formatDate(date) {
    let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hours = d.getHours()+"",
    minutes = d.getMinutes()+"",
    seconds = d.getSeconds()+"";
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hours.length < 2)
      hours = '0' + hours;
    if (minutes.length < 2)
      minutes = '0' + minutes;
    if (seconds.length < 2)
      seconds = '0' + seconds;
  
    return [year, month, day].join('-') + " " + [hours, minutes, seconds].join(':');//"00:00:00";
  }
  const getPayrollDetails = async(from,to) =>   {
    setLoading(true);
    let keys = { "from":from , "to": to,"byProject":byProject };
    try {
      let data = [];
      setUserPayrollData([]);
      let response = await post("Reports/Payroll",keys);
      console.log("RESPONSE: ", response)
      if(byProject){
        for(let i = 0 ; i < response.length ; i++){
          data.push({
            id: i,
            email: response[i].Username,
            name: response[i].Name,
            total: response[i].totalTime,
            productive: response[i].ActiveTime,
            manual: response[i].requested,
            idle: response[i].IdleTime,
            payroll: response[i].payroll,
            project: response[i].project,
            eId : response[i].ID,
            hourCap: response[i].HourCap,
            overtime: response[i].Overtime
          });
        }
      }else{
        for(let i = 0 ; i < response.length ; i++){
          data.push({
            id: i,
            eId : response[i].ID,
            name: response[i].Name,
            email: response[i].Username,
            total: response[i].totalTime,
            productive: response[i].ActiveTime,
            idle: response[i].IdleTime,
            manual: response[i].requested,
            payroll: response[i].payroll,
            overtime: response[i].Overtime,
            project: "",
          });
        }
      }
      setUserPayrollData(data);
      setLoading(false);
    } catch(error) {
      

      console.log(error);
    }
    

  }
  function selectDate(startDate, endDate) {
    setSelectedDate({"from" : formatDate(startDate) , "to" : formatDate(endDate)});

    console.log(formatDate(startDate));
    console.log(formatDate(endDate));

    let customStartDate = new Date(startDate);
    customStartDate.setDate(customStartDate.getDate() + 1);
    let customEndofStart = new Date(endDate);
    customEndofStart.setDate(customEndofStart.getDate() - 1);
  }

 

  const columns = [
    {
      name: "Employee ID",
      selector: (row) => row.eId,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Total Time",
      selector: (row) => row.total,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Productive Time",
      selector: (row) => row.productive,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Idle Time",
      selector: (row) => row.idle,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Manual Time",
      selector: (row) => row.manual,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Overtime",
      selector: (row) => row.overtime,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Payroll Time",
      selector: (row) => row.payroll,
      sortable: true,
      reorder: true,
      wrap: true,
    },
  ];

  const projectcolumns = [
   
    {
      name: "Employee ID",
      selector: (row) => row.eId,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Project",
      selector: (row) => row.project,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Hour Cap",
      selector: (row) => row.hourCap,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Total Time",
      selector: (row) => row.total,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Productive Time",
      selector: (row) => row.productive,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Idle Time",
      selector: (row) => row.idle,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Manual Time",
      selector: (row) => row.manual,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Overtime",
      selector: (row) => row.overtime,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Payroll Time",
      selector: (row) => row.payroll,
      sortable: true,
      reorder: true,
      wrap: true,
    }
  ];

  const weekcolumns = [
    {
      name: "Separtor",
      selector: (row) => row.separator,
      sortable: false,
      reorder: false,
      wrap: false,
    },
    {
      name: "Week",
      selector: (row) => row.week,
      sortable: false,
      reorder: false,
      wrap: false,
    },
    {
      name: "Week Number",
      selector: (row) => row.weekname,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Total Time",
      selector: (row) => row.weektotal,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Productive Time",
      selector: (row) => row.weekproductive,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Idle Time",
      selector: (row) => row.weekidle,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Manual Time",
      selector: (row) => row.weekmanual,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Overtime",
      selector: (row) => row.overtime,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Payroll Time",
      selector: (row) => row.weekpayroll,
      sortable: true,
      reorder: true,
      wrap: true,
    },
  ];
  
  const datecolumns = [
    // {
    //     name: "Separtor",
    //     selector: (row) => row.separatorinitial,
    //     sortable: false,
    //     reorder: false,
    //     wrap: false,
    //     style: [{ width: "48" }],
    //   },
      {
        name: "Separtor",
        selector: (row) => row.separator,
        sortable: false,
        reorder: false,
        wrap: false,
      },
      {
        name: "Week",
        selector: (row) => row.date,
        sortable: false,
        reorder: false,
        wrap: false,
      },
      {
        name: "Date Number",
        selector: (row) => row.datename,
        sortable: true,
        reorder: true,
        wrap: true,
      },
      {
        name: "Total Time",
      selector: (row) => row.datetotal,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Productive Time",
      selector: (row) => row.dateproductive,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Idle Time",
      selector: (row) => row.dateidle,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Manual Time",
      selector: (row) => row.datemanual,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Overtime",
      selector: (row) => row.dateovertime,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Payroll Time",
      selector: (row) => row.datepayroll,
      sortable: true,
      reorder: true,
      wrap: true,
    },
  ];

  const projectweekcolumns = [
    {
      name: "Separtor",
      selector: (row) => row.separator,
      sortable: false,
      reorder: false,
      wrap: false,
    },
    {
      name: "Week",
      selector: (row) => row.week,
      sortable: false,
      reorder: false,
      wrap: false,
    },
    {
      name: "Week Number",
      selector: (row) => row.weekname,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Project",
      selector: (row) => row.weekproject,
      sortable: false,
      reorder: false,
      wrap: false,
    },
    {
      name: "Hour Cap",
      selector: (row) => row.weekhourcap,
      sortable: false,
      reorder: false,
      wrap: false,
    },
    {
      name: "Total Time",
      selector: (row) => row.weektotal,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Productive Time",
      selector: (row) => row.weekproductive,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Idle Time",
      selector: (row) => row.weekidle,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Manual Time",
      selector: (row) => row.weekmanual,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Overtime",
      selector: (row) => row.overtime,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Payroll Time",
      selector: (row) => row.weekpayroll,
      sortable: true,
      reorder: true,
      wrap: true,
    }
  ];

  const projectdatecolumns = [
    {
      name: "Separtor",
      selector: (row) => row.separator,
      sortable: false,
      reorder: false,
      wrap: false,
    },
    {
      name: "Separtor",
      selector: (row) => row.separator,
      sortable: false,
      reorder: false,
      wrap: false,
    },
    {
      name: "Week",
      selector: (row) => row.date,
      sortable: false,
      reorder: false,
      wrap: false,
    },
    {
      name: "Date Number",
      selector: (row) => row.datename,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Project",
      selector: (row) => row.dateProject,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Hour Cap",
      selector: (row) => row.dateHourCap,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Total Time",
      selector: (row) => row.datetotal,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Productive Time",
      selector: (row) => row.dateproductive,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Idle Time",
      selector: (row) => row.dateidle,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Manual Time",
      selector: (row) => row.datemanual,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Overtime",
      selector: (row) => row.dateOvertime,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Payroll Time",
      selector: (row) => row.datepayroll,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Payroll Time",
      selector: (row) => row.dateproject,
      sortable: true,
      reorder: true,
      wrap: true,
    },
  ];

  const data = [];
  useMemo(() => {
    setSelectedDate({"from" : formatDate(StartDate) , "to" : formatDate(EndDate)});
    console.log(selectedDate);

  },[]);
  let fullData = data;

  const [loadingDivConstant,setloadingDivConstant]  = useState (
    <h5>Loading Payroll Details...</h5>
     
  )

  const fetchWeeklyData = async (id, eId) => {
    try {
      const keys = {
        from: selectedDate.from,
        to: selectedDate.to,
        userID: eId,
        projectName: userPayrollData[id].project
      };
  
      const response = await post("Reports/UserPayrollWeekly", keys);
      const dailyResponse = await post("Reports/UserPayrollDaily", keys);
  
      const weekdata = response.map(week => {
        const weeklyData = {
          week: "Week",
          weekname: week.week,
          weektotal: week.totalTime,
          weekproductive: week.ActiveTime,
          weekidle: week.IdleTime,
          weekmanual: week.requested,
          overtime: week.overtime,
          weekpayroll: week.payroll,
          weekproject: week.project ?? "",
          weekhourcap: week.hourCap !== 0 ? week.hourCap : 0,
          datedata: dailyResponse
            .filter(daily => daily.weekNumber === week.week)
            .map(daily => ({
              date: "Date",
              datename: daily.mDate,
              datetotal: daily.ActiveTime + daily.IdleTime,
              dateproductive: daily.ActiveTime,
              datemanual: daily.requested,
              dateidle: daily.IdleTime,
              datepayroll: daily.payroll,
              dateProject: daily.project ?? "",
              dateHourCap: daily.hourCap !== 0 ? daily.hourCap : 0
            }))
        };
        return weeklyData;
      });
  
      const updatedUserPayrollData = [...userPayrollData];
      updatedUserPayrollData[id].weekdata = weekdata;
      setUserPayrollData(updatedUserPayrollData);
  
      console.log(weekdata, updatedUserPayrollData);
    } catch (error) {
      console.error("Error fetching weekly data:", error);
    }
  };

  const logDownloadAction = async() => {
    let user = ReactSession.get("User");
    let keys = { details : `from : ${selectedDate.from} , to: ${selectedDate.to} `, "userID" : user.id, fileDownloaded : "payroll"};
    let response = await post("Logger/CriticalLog",keys);
    //alert(JSON.stringify(response));
  }
  
  function ExpandedComponent({ data, ...props }) {
    if(userPayrollData[data.id].weekdata==null)
      fetchWeeklyData(data.id,data.eId);
    console.log(props,JSON.stringify(data),data.id);
    return (
      <DataTableBase
      
        fullData={userPayrollData}
        columns={byProject ? projectweekcolumns : weekcolumns}
        data={userPayrollData[data.id].weekdata}
        noDataComponent={loadingDivConstant}
        expandableRows="true"
        expandableRowsComponent={FurtherExpandedComponent}
        noTableHead="true"
        nopagination="false"
        
      />
    );
  }

  function FurtherExpandedComponent({ data, ...props }) {
    return (
      <DataTableBase
        fullData={fullData}
        columns={byProject ? projectdatecolumns : datecolumns}
        data={data.datedata}
        noTableHead="true"
        downloadCall={logDownloadAction}
      />
    );
  }

  const handleChange = () => {
    byProject = !byProject;
    setByProject(byProject);
    

  };

  const generate = async () => {
    getPayrollDetails(selectedDate.from,selectedDate.to)
  }

  return (
    <div className="dashboardPayrollMain">
      <div className="dashboardPayrollMain-header">Payroll Details</div>
      <div className="payroll-form">
        <WeekRangeSelector
          selectDate={(start, end) => {
            selectDate(start, end);
          }}
        />
        <div className="project">
          <label className="projectCheckbox" id="project_Checkbox">
            <input
              type="checkbox"
              checked={byProject}
              onChange={handleChange}
            />
            By Project
          </label>
        </div>
        <button className="payroll-form-btn" onClick={generate}>Generate</button>
      </div>
      <div className="payroll-table" id="payroll-full-details">
        {(() => {
                    if (!loading){
                        return (
                          <DataTableBase
                          fullData={userPayrollData}
                          columns={byProject ? projectcolumns : columns}
                          data={userPayrollData}
                          expandableRows="true"
                          expandableRowsComponent={ExpandedComponent}
                          downloadCall={logDownloadAction}
                          fileName={"Payroll"}
                          //   expandableRowsComponentProps={data}
                        />
                        )
                    }else {
                      return <LoadingSpinner/>
                    }
          })()}
      
      </div>
    </div>
  );
}

export default DashboardManagerPayrollFullDetails;
