import { Link } from "react-router-dom";
import EmployeeCard from "../../elements/summaryElements/EmployeeCard";
import "./EmployeeSidebar.css";
import { get } from "../../api/apiCalls";
import React, { Component } from "react";
import { ImSearch } from "react-icons/im";
import { IconContext } from "react-icons";
import "../../ui/SearchButton.css";
import { ReactSession } from 'react-client-session';
import DefaultProfileImage from '../../../images/default.jpg'

class TeamSidebar extends Component {

  constructor() {
    super();
    this.state = {
      users : null,
      items: null,

    };
  }
  componentWillMount() {
    this.getSummaryDetails(null);
  }
  getSummaryDetails = 
  async (searchParam) => {
    
    let user = ReactSession.get("User");
    console.log("USER: ",  user)
    let u = this.state.users;
    if(u==null){
      u = await get("Projects/GetAgentsForProject",{ projectID : user.project.id }).then();
    }
    
    this.setState({
      users: u
    });
    let items=[];
    console.log("USERS: ", u)
    for (const item of u) {
      if(searchParam!=null)
        if(!item.firstName.toLowerCase().includes(searchParam.toLowerCase()))
          continue;
      let image = DefaultProfileImage;
      let link = "/team/dashboard/"+item.id;
      items.push( <Link to={link}>
        <EmployeeCard
        employeeName={item.firstName+' '+item.lastName}
        employeeDesignation={item.jobDescription}
        employeeID={item.id}
        employeePicture={image}
        time="489"
        status="Online"
        location={item.city}
      />
      </Link>);
      
    }
    
    this.setState({ items: items });
    
  };
 
  render() {
    const { items } = this.state;

    return (
      <div className="employee-sidebar">
        {/* <SearchButton searchButtonText="Search Employee" onchange={searchParam}/> */}
        <div className="sidebar-search-button">
        <div className="search-button-text search-input">
            <input className="search-input-text" placeholder="Search Employee" onChange={e => this.getSummaryDetails(e.target.value)} />
            <IconContext.Provider
              value={{
                color: "black",
                size: "30px",
              }}
            >
              <div>
                <ImSearch />
              </div>
            </IconContext.Provider>
          </div>
        </div>
        <div className="employee-cards-list">
          {items}
        </div>
      </div>
    );
  }
}

export default TeamSidebar;
