import Header from "../components/layout/header/Header";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import AdminAnnouncementsPage from "../components/admin/AdminAnnouncementsPage";

function AdminAnnouncements() {
  return (
    <div>
      <Header pageName="Dashboard" />
      <ManagerSidebar />
      <AdminAnnouncementsPage />
    </div>
  );
}

export default AdminAnnouncements;
