import LandingHeader from "../components/layout/landing/LandingHeader";
import LandingCenter from "../components/layout/landing/LandingCenter";
import LandingFooter from "../components/layout/landing/LandingFooter";

function LandingPage() {
  return (
    <div className="landing-page">
      <LandingHeader />
      <LandingCenter />
      <LandingFooter />
    </div>
  );
}

export default LandingPage;
