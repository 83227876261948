import "./ActivityType.css";

function ActivityType(props) {
  return (
    <div className="activityTypeCard">
      <div className="activityTime">{props.activityTime}</div>
    </div>
  );
}

export default ActivityType;
