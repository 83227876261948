import { Link } from "react-router-dom";
import { ImSearch } from "react-icons/im";
import { IconContext } from "react-icons";

import "./SearchSection.css";

function SearchSection(props) {
  return (
    <div className="searchSection">
      <Link to={props.destinationLink == null ? '/manager' : props.destinationLink}>
        <IconContext.Provider
          value={{
            color: "black",
            size: "30px",
          }}
        >
          <div className="searchIcon">
            <ImSearch />
          </div>
        </IconContext.Provider>
        <div className="searchText">{props.searchText}</div>
      </Link>
    </div>
  );
}

export default SearchSection;
