import Header from "../components/layout/header/Header";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import UpdateRate from "../components/admin/UpdateRate"


function AdminUpdateRate() {
  return (
    <div>
      <Header pageName="Dashboard" />
      <ManagerSidebar />
      <UpdateRate />
    </div>
  );
}

export default AdminUpdateRate;
