import SignIn from "../components/signedpages/SignIn";
import sidePanelImage from "../images/sidepanelimage.png";

function SignInPage() {
  return (
    <div className="signedpages">
      <SignIn />
      <img src={sidePanelImage} alt="" />
    </div>
  );
}

export default SignInPage;
