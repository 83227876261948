import TeamSidebar from "../sidebar/TeamSidebar";
import dashboardImage from "../../../images/dashboardImage.png";

import "./DashboardMain.css";

function TeamDashboard() {
  return (
    <div className="dashboardMain">
      <TeamSidebar />
      <div className="dashboard-image">
        <img src={dashboardImage} alt="" size="auto" />
      </div>
    </div>
  );
}

export default TeamDashboard;
