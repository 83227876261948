import Header from "../components/layout/header/Header";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import UserPersonalInfo from "../components/manager/employeeprofile/UserPersonalInfo";


function PersonalInfo() {
  return (
    <div>
      <Header pageName="Dashboard" />
      <ManagerSidebar />
      <UserPersonalInfo/>
    </div>
  );
}

export default PersonalInfo;
