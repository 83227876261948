import EmployeeSidebar from "../sidebar/EmployeeSidebar";
import { ReactSession } from 'react-client-session'
import { ManagerEmployeeSidebar } from "../sidebar/ManagerEmployeeSidebar";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { OrderList } from 'primereact/orderlist';
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Chart } from "primereact/chart";
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { useEffect, useState } from "react";
import { get } from "../../api/apiCalls";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";


import 'primeicons/primeicons.css';
import "./DashboardMain.css";
import { Button } from "primereact/button";

function DashboardMain() {
  const user = ReactSession.get("User")
  const [requests, setRequests] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filters, setFilters] = useState(null);
  const [requestsFilters, setRequestsFilters] = useState(null);
  const [selectedProject, setSelectedProject] = useState('');
  const [usersStatus, setUserStatus] = useState([]);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chartLabels, setChartLabels] = useState([]);
  const [chartTrackedTime, setChartTrackedTime] = useState([]);
  const [chartIdle, setChartIdle] = useState([]);
  const [chartManualTime, setChartManualTime] = useState([]);
  const [chartMarked, setChartMarked] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [globalRequestsFilterValue, setGlobalRequestsFilterValue] = useState('');
  let [totalRequest, setTotalRequests] = useState(0);
  let [loading, setLoading] = useState(false);

  const statusBodyTemplate = (userStatus) => {
    return <Tag value={userStatus.status} severity={getSeverity(userStatus)}></Tag>;
  };

  const userBodyTemplate = (userRequests) => {
    let url = `/manager/user-requests/${userRequests.userID}`
    return (
      <Link className="manager-dashboard-requests" to={url}>
        <strong>{userRequests.name}</strong>
      </Link>
    )
  };

  const typeBodyTemplate = (userRequests) => {
    return (
      <strong className={userRequests.type === 'Manual Time'? 'manualTime': 'overtime'}>{userRequests.type}</strong>
    )
  };

  const getProejcts = async() => {
    let list = []
    if(user.role === 1){
      list = await get(`Projects?disabled=${false}`)
    }else{
      list = await get(`Projects/GetProjectsForAgent?UserID=${user.id}`)
    }

    setProjects(list)
  }

  const getSeverity = (userStatus) => {
    switch (userStatus.status) {
        case 'Online':
            return 'success';

        case 'Offline':
            return 'danger';

        default:
            return null;
    }
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initRequestsFilters = () => {
    setRequestsFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    });
    setGlobalRequestsFilterValue('');
  };

  const onGlobalRequestsFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...requestsFilters };

    _filters['global'].value = value;

    setRequestsFilters(_filters);
    setGlobalRequestsFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="users-table-header">
        <h5>Users: {usersStatus.length}</h5>
        <div className="table-filters">
          <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search" />
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </IconField>
        </div>
      </div>
    );
  };

  const renderRequestsHeader = () => {
    return (
      <div className="users-table-header">
        <h5>Requests: {totalRequest}</h5>
        <div className="table-filters">
          <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search" />
              <InputText value={globalRequestsFilterValue} onChange={onGlobalRequestsFilterChange} placeholder="Keyword Search" />
            </IconField>
        </div>
      </div>
    );
  };

  const header = renderHeader()
  const requestsHeader = renderRequestsHeader()
  
  const getRequests = async(id) => {
    let list = []
    if(id > 0){
      list = await get(`Request?projectID=${id}`)
    }else{
      list = await get('Request')
    }
    setTotalRequests(list.length)
    setRequests(list)

  }

  const getUsersStatus = async(id) => {
    let list = []
    if(id > 0){
      list = await get(`DesktopApp/GetUsersStatus?projectID=${id}`)
    }else{
      list = await get('DesktopApp/GetUsersStatus')
    }
    setUserStatus(list)
  }

  const getTrackedTime = async(id) => {
    setLoading(true);
    let list = [];
    let labels = [];
    let tracked = [];
    let idle = [];
    let mt = [];
    let marked = [];

    if (id > 0) {
        list = await get(`TrackingData/GetMonthlyReport?projectID=${id}`);
    } else {
        list = await get(`TrackingData/GetMonthlyReport`);
    }

    for (const item of list) {
        labels.push(item.week);
        tracked.push(item.trackedTime);
        idle.push(item.idle);
        mt.push(item.manualTime);
        marked.push(item.marked);
    }

    // Atualiza os estados com os valores calculados
    setChartLabels(labels);
    setChartTrackedTime(tracked);
    setChartIdle(idle);
    setChartManualTime(mt);
    setChartMarked(marked);

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    // Use as variáveis locais para criar o objeto data e options
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Tracked Time',
                backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                borderColor: documentStyle.getPropertyValue('--blue-500'),
                data: tracked,
            },
            {
                label: 'Idle',
                backgroundColor: documentStyle.getPropertyValue('--green-500'),
                borderColor: documentStyle.getPropertyValue('--green-500'),
                data: idle,
            },
            {
                label: 'Manual Time',
                backgroundColor: documentStyle.getPropertyValue('--orange-500'),
                borderColor: documentStyle.getPropertyValue('--orange-500'),
                data: mt,
            },
            {
                label: 'Marked',
                backgroundColor: documentStyle.getPropertyValue('--pink-500'),
                borderColor: documentStyle.getPropertyValue('--pink-500'),
                data: marked,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    fontColor: textColor,
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary,
                    font: {
                        weight: 500,
                    },
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
            y: {
                ticks: {
                    color: textColorSecondary,
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false,
                },
            },
        },
    };

    setChartData(data);
    setChartOptions(options);
    setLoading(false);
};


  useEffect(() => {
    const generetaList = async() =>{
      await getRequests();
      await getUsersStatus();
      await getProejcts();
      await getTrackedTime();
    }
    initFilters()
    initRequestsFilters()
    generetaList()
  }, [])

  const filterAllByProject = async(e) => {
    setLoading(true)
    setSelectedProject(e.value.Name || e.value.name)
    await getTrackedTime(e.value.Id || e.value.id)
    await getUsersStatus(e.value.Id || e.value.id)
    await getRequests(e.value.Id || e.value.id)
  }

  return (
    <div className="dashboardMain">
      {user.role == 1 ? (
        <>
          <div>
            <EmployeeSidebar />
          </div>
          <div className="manager-dash-box">
            <div className="manager-dash-project-dropdown">
              <Dropdown value={selectedProject} onChange={filterAllByProject} options={projects} optionLabel="Name" 
                  editable placeholder="Select a Project" className="w-full md:w-14rem" />
            </div>
            <div className="manager-dash-requests-box">
              <DataTable value={requests} header={requestsHeader} tableStyle={{ minWidth: '10rem' }} filters={requestsFilters} globalFilterFields={['Name', 'name']} emptyMessage="No requests found." onFilter={(e) => setRequestsFilters(e.filters)}>
                <Column header="User" body={userBodyTemplate}></Column>
                <Column field="project" header="Project"></Column>
                <Column header="Type" body={typeBodyTemplate}></Column>
                <Column field="count" header="Hours"></Column>
              </DataTable>
            </div>
            <div className="manager-dash-usersStatus-box">
              <DataTable value={usersStatus} header={header} tableStyle={{ minWidth: '10rem' }} filters={filters} globalFilterFields={['Name', 'name']} emptyMessage="No customers found." onFilter={(e) => setFilters(e.filters)}>
                <Column field="userID" header="ID" style={{ minWidth: '4rem' }}></Column>
                <Column field="name" header="Name" style={{ minWidth: '10rem' }} />
                <Column header="Status" body={statusBodyTemplate} className=""></Column>
              </DataTable>
            </div>
            {!loading ? (
              <div className="manager-dash-chart-box">
                <h5>T4W Project's Time</h5>
                <Chart type="bar" data={chartData} options={chartOptions} />
              </div>
            ) : <LoadingSpinner />}
          </div>
        </>
      ) : (
        <>
          <div>
            <ManagerEmployeeSidebar />
          </div>
          <div className="manager-dash-box">
            <div className="manager-dash-project-dropdown">
              <Dropdown value={selectedProject} onChange={filterAllByProject} options={projects} optionLabel="name" 
                  editable placeholder="Select a Project" className="w-full md:w-14rem" />
            </div>
            <div className="manager-dash-requests-box">
              <DataTable value={requests} header={requestsHeader} tableStyle={{ minWidth: '10rem' }} filters={requestsFilters} globalFilterFields={['Name', 'name']} emptyMessage="No requests found." onFilter={(e) => setRequestsFilters(e.filters)}>
                <Column header="User" body={userBodyTemplate}></Column>
                <Column field="project" header="Project"></Column>
                <Column header="Type" body={typeBodyTemplate}></Column>
                <Column field="count" header="Count"></Column>
              </DataTable>
            </div>
            <div className="manager-dash-usersStatus-box">
              <DataTable value={usersStatus} header={header} tableStyle={{ minWidth: '10rem' }} filters={filters} globalFilterFields={['Name', 'name']} emptyMessage="No customers found." onFilter={(e) => setFilters(e.filters)}>
                <Column field="userID" header="ID"></Column>
                <Column field="name" header="Name"></Column>
                <Column header="Status" body={statusBodyTemplate}></Column>
              </DataTable>
            </div>
            {!loading ? (

              <div className="manager-dash-chart-box">
                <Chart type="bar" data={chartData} options={chartOptions} />
              </div>
            ) : <LoadingSpinner />}
          </div>
        </>
      )}
      
    </div>
  );
}

export default DashboardMain;
