import { NavLink } from "react-router-dom";

function AdminHeaderLinks() {
  return (
    <div className="links">
      <ul>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/settings/sso-setup">
            SSO Setup
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/settings/announcements">
            Announcements
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/settings/web-tracker">
            Web Tracker
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default AdminHeaderLinks;