import Header from "../components/layout/header/Header";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import UserOvertime from "../components/manager/employeeprofile/UserOvertime";


function UserProfile() {
  return (
    <div>
      <Header pageName="Dashboard" />
      <ManagerSidebar />
      <UserOvertime />
    </div>
  );
}

export default UserProfile;
