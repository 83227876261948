import { NavLink } from "react-router-dom";
import EmployeeShortProfile from "../../elements/summaryElements/EmployeeShortProfile";
import { ReactSession } from 'react-client-session';

import "./ProfileSubHeaderLinks.css";

function ProfileSubHeaderLinks() {
  let user = ReactSession.get("User");

  return (
    <div className="profile-subheader">
      <EmployeeShortProfile
            employeeName={user.firstName + ' ' + user.lastname}
            employeePronoun={user.pronoun}
            employeeDesignation={user.jobDescription}
            employeeID={user.id}
            employeeUsername={user.username}
            employeeLocation={user.city}
            employeePicture={user.image}
      />
      <ul className="profile-links">
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/profile/info">
            Personal Info
          </NavLink>
        </li>
        {!user.disabled && (
          <li>
            <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/profile/work">
              Links
            </NavLink>
          </li>
        )}
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/profile/requests">
            Requests
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/profile/payment">
            Payments
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default ProfileSubHeaderLinks;
