import "./AdminSettings.css";
import TextField from '@mui/material/TextField';
import { get,post } from "../api/apiCalls";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { useMemo, useState } from "react";
import Switch from "react-switch";
import AdminHeaderLinks from "../layout/header/AdminHeaderLinks"
function AdminSettings () {
    
    let [settings,setSettings] = useState(null);

    let saveSettings = async () => {
        let keys = {
            settingName : "SSO",
            value : JSON.stringify(settings)
        }
        await post("Settings",keys);
        alert("Settings Saved");
    }

    let getSettings = async () => {
        let settings = JSON.parse((await get("Settings",{settingName : "sso"})).value);
        console.log("SETTINGS: ", settings)
        setSettings(settings);
       
    }

    useMemo(async () => {
        if(settings==null)
            getSettings();
      },[]);
    return (
   
    <div className="settingsContainer">
        <AdminHeaderLinks/>
        { settings && 
            <Box sx={{ flexGrow: 1 }} className="settingsBody">
                <Grid container spacing={2}>
                    <Grid xs={10}>
                        <h3 className="settingsLabel">SSO settings</h3>
                    </Grid>
                    
                    <Grid xs={6}>
                    <TextField
                        id="outlined-controlled"
                        label="Keycloak URL"
                        defaultValue={settings.keycloakURL}
                        className="txtField"
                        onChange={(e) => setSettings({...settings,keycloakURL : e.target.value})}
                    />
                    </Grid>
                    <Grid xs={6} />

                    <Grid xs={6}>
                    <TextField
                        id="outlined-controlled"
                        label="SSO Redirect URL"
                        defaultValue={settings.ssoRedirectUrl}
                        className="txtField"
                        onChange={(e) => setSettings({...settings,ssoRedirectUrl : e.target.value})}
                    />
                    </Grid>
                    <Grid xs={6} />

                    <Grid xs={6}>
                    <TextField
                        id="outlined-controlled"
                        label="realm"
                        defaultValue={settings.realm}
                        className="txtField"
                        onChange={(e) => setSettings({...settings,realm : e.target.value})}
                    />
                    </Grid>
                    <Grid xs={6} />

                    <Grid xs={6}>
                    <TextField 
                        id="outlined-controlled"
                        label="Client Id"
                        defaultValue={settings.clientID}
                        className="txtField"
                        minRows={5}
                        onChange={(e) => setSettings({...settings,clientID : e.target.value})}

                    />
                    </Grid>
                    <Grid xs={6} />
                    <Grid xs={6} >
                        <label htmlFor="Show SSO" className="ssoSwitchLabel" >Enable SSO</label>
                        <Switch  checked={settings.showSSO} 
                            onChange={(e) => setSettings({...settings,showSSO : e})}
                        />
                    </Grid>
                    <Grid xs={6} />
                    <Grid xs={6}>
                    <button className="saveBtn" onClick={() => saveSettings()}>Save Settings</button>
                
                    </Grid>
                </Grid>
            </Box>
        }
    </div>);
}

export default AdminSettings;